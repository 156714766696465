import { useEffect } from "react";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { GeolocationRetryState, GeolocationRetryStatus } from "../types";

export const useGeoComplyRetry = (
  retryLocateUser: NullaryFn<void>,
  retryStatus?: GeolocationRetryStatus
) => {
  useEffect(() => {
    if (retryStatus?.state === GeolocationRetryState.REQUESTED) {
      retryLocateUser();
    }
  }, [retryStatus?.state]);
};
