import axios from "axios";
import TvgConfig from "@tvg/conf";
import { GeoPacket } from "./types/solus";

const { config, getClientId } = TvgConfig();
const clientId = getClientId();
const serviceHost = config("externalServices.fanduel");

const commonHeaders = {
  Authorization: `Basic ${clientId}`
};

export const getLicense = (
  state: string,
  product: string,
  licenseType = "SOLUS"
) =>
  axios({
    method: "GET",
    url: `${serviceHost}/geocomply/license/${state}/${product}/${licenseType}`,
    headers: commonHeaders,
    withCredentials: true
  });

export const renewLicense = (
  license: string,
  state: string,
  product: string,
  licenseType = "SOLUS"
) =>
  axios({
    method: "POST",
    url: `${serviceHost}/geocomply/license/${state}/${product}/${licenseType}`,
    withCredentials: true,
    headers: commonHeaders,
    data: {
      license
    }
  });

export const decryptGeopacket = (authToken: string, geoPacket: GeoPacket) =>
  axios({
    method: "POST",
    url: `${serviceHost}/geocomply/submit`,
    headers: {
      ...commonHeaders,
      "content-type": "application/json",
      "X-Auth-Token": authToken
    },
    withCredentials: true,
    data: geoPacket
  });
