import React, { useEffect } from "react";
import { Overlay, Loading, Text } from "./styled-components";

const getSize = (size) => {
  const sizes = {
    small: 8,
    medium: 17,
    large: 25
  };
  return sizes[size] ? sizes[size] : sizes.small;
};

const ProcessingOverlay = ({
  isRounded,
  isTransparent,
  customTop,
  dotSize,
  text,
  isBlackTransparent,
  smallText,
  isFullScreen,
  isURP,
  isFinalModal
}) => {
  useEffect(() => {
    if (isFullScreen && typeof window !== "undefined") {
      document.body.classList.add("modal");
    }
    return () => {
      if (isFinalModal !== false) document.body.classList.remove("modal");
    };
  }, []);

  return (
    <Overlay
      data-qa-label="processingRequest"
      rounded={isRounded}
      isTransparent={isTransparent}
      top={customTop}
      isBlackTransparent={isBlackTransparent}
      isFullScreen={isFullScreen}
    >
      <Loading spanSize={getSize(dotSize)} isURP={isURP}>
        <span />
        <span />
        <span />
      </Loading>

      {text && (
        <Text isBlackTransparent={isBlackTransparent} smallText={smallText}>
          {text}
        </Text>
      )}
    </Overlay>
  );
};

ProcessingOverlay.defaultProps = {
  isRounded: false,
  isTransparent: false,
  customTop: 0,
  dotSize: "small",
  text: "",
  isBlackTransparent: false,
  smallText: false
};

export default ProcessingOverlay;
