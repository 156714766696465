import {
  BinaryFn,
  NullaryFn,
  TernaryFn,
  UnaryFn
} from "@tvg/ts-types/Functional";

export interface GeoClientDataGSM {
  lat: string;
  lon: string;
  acc: string;
  ts: string;
  err: number;
}

export interface GeoClientWiFi {
  lat: number;
  acc: number;
  altitudeAccuracy: string;
  speed: string;
  heading: string;
  lon: number;
  altitude: string;
  timestamp: number;
  err: number;
  simulated: string;
  ts: number;
  loc_count: number;
}

export interface GeoClientSpoofedLocation {
  lat: number;
  acc: number;
  altitudeAccuracy: string;
  speed: string;
  heading: string;
  lon: number;
  altitude: string;
  timestamp: number;
  err: number;
  simulated: string;
  ts: number;
}
export interface GeoClientWatchCoords {
  lat: number;
  acc: number;
  altitudeAccuracy: string;
  speed: string;
  heading: string;
  lon: number;
  altitude: string;
  timestamp: number;
  err: number;
  simulated: string;
  ts: number;
}

export interface GeoClientData {
  uname: string;
  reason: string;
  gsm: GeoClientDataGSM;
  wifi: GeoClientWiFi;
  wb: string;
  os: string;
  os_description: string;
  lang: string;
  tz: string;
  session: string;
  sres: string;
  retry: number;
  dnsUUID: string;
  spoofedLocation: GeoClientSpoofedLocation;
  config_ver: string;
  auth: string;
  version: string;
  session_retry: number;
  _watchCoords: Array<GeoClientWatchCoords>;
  custom_fields: HTMLElement;
  browser_mark: HTMLElement;
  anti_spoof: HTMLElement;
  simulation: HTMLElement;
  geolocation_timeout: HTMLElement;
  sdk_h: string;
}

export enum GeoClientErrorCodes {
  CLNT_OK = 0,
  CLNT_ERROR_UNEXPECTED = 600,
  CLNT_ERROR_NETWORK_CONNECTION = 602,
  CLNT_ERROR_SERVER_COMMUNICATION = 603,
  CLNT_ERROR_CLIENT_SUSPENDED = 604,
  CLNT_ERROR_DISABLED_SOLUTION = 605,
  CLNT_ERROR_LICENSE_INVALID_FORMAT = 606,
  CLNT_ERROR_LICENSE_UNAUTHORIZED = 607,
  CLNT_ERROR_LICENSE_EXPIRED = 608,
  CLNT_ERROR_INVALID_CUSTOM_FIELDS = 609,
  CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS = 614,
  CLNT_ERROR_INVALID_RESPONSE = 620,
  CLNT_ERROR_INVALID_HMAC = 640,
  CLNT_ERROR_BROWSER_NOT_COMPATIBLE = 801,
  CLNT_ERROR_BROWSER_GEOLOCATION_UNAVAILABLE = 802,
  CLNT_ERROR_BROWSER_GEOLOCATION_DENIED = 803,
  CLNT_ERROR_BROWSER_GEOLOCATION_TIMEOUT = 804,
  CLNT_ERROR_BROWSER_GEOLOCATION_UNKNOWN = 805
}

export enum GeoClientErrorMessages {
  ERROR_CODE_0 = "OK",
  ERROR_CODE_600 = "Unexpected error occurred",
  ERROR_CODE_602 = "The network connection is not available",
  ERROR_CODE_603 = "Server communication error",
  ERROR_CODE_604 = "The client account is suspended",
  ERROR_CODE_605 = "The HTML5 solution is disabled",
  ERROR_CODE_606 = "The license has invalid format",
  ERROR_CODE_607 = "The license is unauthorized by server",
  ERROR_CODE_608 = "The license is expired",
  ERROR_CODE_609 = "The custom fields list is invalid",
  ERROR_CODE_614 = "Previous geolocation is not finished",
  ERROR_CODE_620 = "Invalid server Response",
  ERROR_CODE_640 = "Invalid HMAC",
  ERROR_CODE_801 = "Current browser is incompatible with HTML5 geolocation",
  ERROR_CODE_802 = "Browser HTML5 geolocation is unavailable",
  ERROR_CODE_803 = "Browser HTML5 geolocation access denied",
  ERROR_CODE_804 = "Browser HTML5 geolocation timed out",
  ERROR_CODE_805 = "Browser HTML5 geolocation unknown problem"
}

export interface GeoClientLicenseHost {
  timeout: string;
  url: string;
}

export interface GeoClientLicense {
  expires: string;
  akey: string;
  host: GeoClientLicenseHost;
  auth: string;
  log_endpoint: string;
}

export const GeoClientHintReasons = {
  BROWSER_GEOLOCATION_UNAVAILABLE: "802",
  BROWSER_GEOLOCATION_DENIED: "803",
  BROWSER_GEOLOCATION_TIMEOUT: "804",
  BROWSER_GEOLOCATION_UNKNOWN: "805",
  BROWSER_PEM_GRANTED: "granted",
  BROWSER_PEM_NOT_SUPPORTED: "notSupported",
  BROWSER_PEM_PROMPT: "prompt",
  BROWSER_PEM_DENIED: "denied"
};

export interface GeoClientConfig {
  _version: string;
  engine_url: string;
  html5_high_accuracy: string;
  html5_timeout: string;
  html5_maximum_age: string;
  html5_enabled: string;
  html5_ddp_url: string;
  engine_timeout: string;
  retransmission_attempts_count: string;
  solus_enable_location_disabled_alert: string;
  solus_mobile_browsergeo_alert_msg: string;
  solus_enable_mobile_browsergeo_alert: string;
  solus_enable_desktop_browsergeo_alert: string;
  solus_desktop_browsergeo_alert_msg: string;
  solus_mobile_browsergeo_alert_interval: string;
  low_accuracy_timeout: string;
  solus_enable_features: string;
  solus_fp_timeout: string;
  solus_fpid_enable: string;
  solus_fpid_exclusion: string;
  solus_overwrite_spoofed_methods: string;
  solus_alert_denied_users: string;
  solus_alert_until_counter: string;
  solus_alert_until_timer: string;
  solus_extensions: string;
  solus_desktop_browsergeo_alert_interval: string;
  solus_desktop_alert_denied_users: string;
  solus_desktop_alert_until_counter: string;
  solus_desktop_alert_until_timer: string;
  solus_mobile_location_disabled_alert_message: string;
  solus_desktop_enable_location_disabled_alert: string;
  solus_desktop_location_disabled_alert_message: string;
  solus_location_spoofing_details: string;
  solus_watch_within: string;
  solus_watch_limit: string;
  solus_watch_always: string;
  solus_enable_watch: string;
  html5_timeout_extended: string;
  solus_desired_accuracy?: string;
  solus_mobile_check_past_transaction_alert: string;
  solus_desktop_check_past_transaction_alert: string;
  solus_enable_client_i18n: string;
  solus_enable_hmac: string;
  solus_devtools_debugger: string;
  solus_ux_configs_pt_br: string;
  solus_ux_configs_pt: string;
  solus_ux_configs_en_us: string;
  solus_ux_configs_en: string;
  solus_ux_configs: string;
}

export interface GeoClientEvents {
  _events: {
    maxListeners: number;
  };
  _conf: {
    wildcard: number;
    newListener: number;
    maxListeners: number;
  };
  newListener: boolean;
  wildcard: boolean;
  listenerTree: Record<string, Record<string, unknown>>;
  event: GeoClientEvent;
  on: BinaryFn<string, Function, void>;
}

export interface GeoClientFPFeatures {
  extensions: boolean;
  fonts_js: boolean;
  local_ip: boolean;
  evercookie_webcache: boolean;
  pem_revoke: boolean;
  private_mode: boolean;
  audio: boolean;
  webgl: boolean;
}

export interface GeoClientMVP {
  error_code: number;
  srv_error_code: number;
  http_error_code: number;
  clnt_error_code: number;
  flow_ver: string;
  fallback: number;
}

export interface GeoClientPermissionCheck {
  onGranted: NullaryFn<void>;
  onDenied: NullaryFn<void>;
  onPrompt: NullaryFn<void>;
  onNotSupported: NullaryFn<void>;
}

export interface GeoClientPermission {
  after: BinaryFn<unknown, UnaryFn<string, void>, void>;
  before: NullaryFn<void>;
  check: UnaryFn<GeoClientPermissionCheck, void>;
  get: UnaryFn<NullaryFn<void>, void>;
  isDenied: UnaryFn<boolean, void>;
  isGranted: UnaryFn<boolean, void>;
}

export interface GeoClientUtilsIS {
  version: string;
  touch: boolean;
  os: string;
  windows: boolean;
  safari: boolean;
  android: boolean;
  edge: boolean;
  ieOnWindowsGte10: boolean;
  opera_mobile: boolean;
  chrome: boolean;
  ipad: boolean;
  description: string;
  mobile: boolean;
  osx: boolean;
  wb: string;
  ios_webview: boolean;
  android_browser: boolean;
  firefox: boolean;
  ie: boolean;
  ios: boolean;
  opera: boolean;
  os_version: string;
  ieLt10: boolean;
}

export type GeoClientUtilsAESFunction = TernaryFn<
  string,
  string,
  number,
  string
>;

export interface GeoClientUtilsAES {
  decrypt: GeoClientUtilsAESFunction;
  encrypt: GeoClientUtilsAESFunction;
  decryptMvp: GeoClientUtilsAESFunction;
  encryptMvp: GeoClientUtilsAESFunction;
}

export interface GeoClientUtils {
  is: GeoClientUtilsIS;
  aes: GeoClientUtilsAES;
}

export interface GeoClient {
  ErrorCodes: typeof GeoClientErrorCodes;
  ErrorMessages: Record<GeoClientErrorCodes, GeoClientErrorMessages>;
  HintReasons: typeof GeoClientHintReasons;
  authVersion: string;
  cachedConfig: GeoClientConfig | null;
  configServiceOnErrorCallbacks: Array<NullaryFn<void>>;
  configServiceOnSuccessCallbacks: Array<NullaryFn<void>>;
  compatible: boolean;
  config: GeoClientConfig;
  customFields: {
    clear: NullaryFn<void>;
    get: UnaryFn<string, void>;
    getHtml: NullaryFn<string>;
    set: BinaryFn<string, string, void>;
  };
  customTimeout: number | null;
  data: GeoClientData;
  encryptionFlowNumber: string;
  events: GeoClientEvents;
  forceEnabled?: boolean;
  fp_features?: GeoClientFPFeatures;
  geolocateStart: number;
  inProgress: string;
  language: string;
  lastAbortedTime?: number;
  lastErrorCode?: keyof typeof GeoClientErrorCodes;
  lastRequestTime?: Date;
  lastRequestUrl?: string;
  license: GeoClientLicense;
  mvp: GeoClientMVP;
  pemState: string;
  permission: GeoClientPermission;
  rk?: unknown;
  rs?: unknown;
  serverPubKey?: string;
  utils: GeoClientUtils;
  watchTimer: number;
  xhr?: unknown;
  setFlowVersion: UnaryFn<string, void>;
  setLang: UnaryFn<string, void>;
  setLicense: UnaryFn<string, void>;
  setReason: UnaryFn<string, void>;
  setUserId: UnaryFn<string, void>;
  setUserSessionId: UnaryFn<string, void>;
  setUserPhoneNumber: UnaryFn<string, void>;
  request: NullaryFn<void>;
}

export enum GeoClientEvent {
  SUCCESS_ENGINE = "engine.success",
  FAILED_ALL = "*.failed",
  FAILED_REVISE = "revise.failed",
  FAILED_CONFIG = "config.failed",
  FAILED_ENGINE = "engine.failed"
}
export enum PreCheckClientErrorCodes {
  CLNT_ERROR_NETWORK_CONNECTION = 602,
  CLNT_ERROR_SERVER_COMMUNICATION = 603,
  CLNT_ERROR_CLIENT_SUSPENDED = 604,
  CLNT_ERROR_LICENSE_UNAUTHORIZED = 607,
  CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS = 614,
  CLNT_ERROR_SERVICE_URL_REQUIRED = 640,
  CLNT_ERROR_AKEY_REQUIRED = 641,
  CLNT_ERROR_SKEY_REQUIRED = 642,
  CLNT_ERROR_API_CONSUMER_DISABLED = 660,
  CLNT_ERROR_CLIENT_NOT_ASSIGNED = 661,
  CLNT_ERROR_NO_STATE = 662,
  CLNT_ERROR_API_DISABLED = 663
}

export enum PreCheckClientErrorMessages {
  ERROR_CODE_602 = "The network connection is not available",
  ERROR_CODE_603 = "Server communication error",
  ERROR_CODE_604 = "The client account is suspended",
  ERROR_CODE_607 = "The license is unauthorized by server",
  ERROR_CODE_614 = "Previous request is not finished",
  ERROR_CODE_640 = "Invalid service url",
  ERROR_CODE_641 = "Invalid api key",
  ERROR_CODE_642 = "Invalid secret key",
  ERROR_CODE_660 = "API consumer disabled",
  ERROR_CODE_661 = "Client not assigned",
  ERROR_CODE_662 = "Cannot determine state",
  ERROR_CODE_663 = "API disabled"
}

export interface PreCheckClient {
  ERROR_CODES: typeof PreCheckClientErrorCodes;
  ERROR_MESSAGES: Record<PreCheckClientErrorCodes, PreCheckClientErrorMessages>;
  akey?: string;
  pending: boolean;
  serviceUrl?: string;
  skey?: string;
  check: BinaryFn<UnaryFn<string, void>, BinaryFn<string, string, void>, void>;
  setApiKey: UnaryFn<string, void>;
  setSecretKey: UnaryFn<string, void>;
  setServiceUrl: UnaryFn<string, void>;
}
