import { batch } from "react-redux";
import { attempt, get } from "lodash";
import mediator from "@tvg/mediator";
import type { Dispatch } from "redux";
import { UserInfo, UserJurisdiction } from "@fdr/types/ts/User";
import { getUserJurisdiction } from "@tvg/sh-utils/userJurisdictionUtils";
import { getWalletBalances } from "@tvg/sh-utils/walletUtils";
import sendToAppsFlyer, { AppsFlyerEvents } from "@tvg/utils/appsflyerUtils";
import { getFanduelUserProfile } from "@tvg/api/aw";
// @ts-ignore
import { setIsLoginErrorPage } from "@tvg/sh-lib-account-actions/src/actions/login";
// @ts-ignore
import { openErrorModal } from "@tvg/sh-lib-account-actions/src/actions/modal";
import profileService, {
  TVG_ACCOUNT_ID_NOT_FOUND_EXCEPTION,
  FDR_USER_NOT_VERIFIED_EXCEPTION,
  INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE,
  FDR_EXPIRED_TOKEN_EXCEPTION
} from "@tvg/api/uam";
import {
  errorLocationSplashAction,
  resetStateLocationSplashAction,
  fdrGhostAccountExceptionAction
} from "@urp/location-splash/src/actions";
import {
  setUserData,
  setIsVerified,
  setUserJurisdiction,
  openInvalidResidentialAddressModal,
  setRaceAlertsUserId
} from "@fdr/shared-actions/UserActions";
import {
  addCookie,
  getUserSessionData,
  getAuthTokenCookie,
  createAuthTokenCookie
} from "@tvg/sh-utils/sessionUtils";
import type { User } from "@tvg/ts-types/User";
import { logout } from "@tvg/sh-lib-account-wallet/src/session/logout";
import {
  onTriggerGeolocation,
  isXSell,
  isFdrProduct
} from "@tvg/sh-utils/mobileUtils";
import { setDatadogRumUserIfEnabled } from "@fdr/datadog-rum";
import { onOpenComplianceModal } from "../utils/mobileUtils";

type CompleteLoginExtraOptions = Partial<{
  userName: string;
  enforceAcceptTerms: boolean;
  isGhostAccountError: boolean;
}>;

// TODO: confirm if this should be here!
export const loginSuccessGtm = (accountId: string, userBalance?: number) => {
  mediator.base.dispatch({
    type: "FDR_LOGIN_SUCCESS",
    payload: {
      accountId,
      userBalance
    }
  });
};

export const handleLoginResponse = (
  response: {
    status: string;
    data: {
      user: UserInfo;
      jurisdiction: UserJurisdiction | null;
      isVerified: boolean;
    };
  },
  dispatch: Dispatch,
  options: CompleteLoginExtraOptions = {}
) => {
  const user = get(response, "data.user", null);

  const jurisdiction = get(response, "data.jurisdiction", null);
  const isVerified = get(response, "data.isVerified");
  const accountNumber = get(user, "accountNumber", 0);

  batch(() => {
    dispatch(setUserData({ logged: true, userLogout: false, user }));
    dispatch(setIsVerified(isVerified));
    dispatch(setUserJurisdiction(jurisdiction));
  });

  const isTermsAccepted = get(jurisdiction, "accepted", false);
  if (isVerified && !isTermsAccepted && options.enforceAcceptTerms) {
    onOpenComplianceModal();
  }

  getWalletBalances(dispatch)
    .then((balance) => {
      const balancePlayable = get(balance, "racingPlayable");
      loginSuccessGtm(accountNumber, balancePlayable);
    })
    // $FlowFixMe
    .catch(() => loginSuccessGtm(accountNumber, undefined));

  // Execute callback on success login
  setTimeout(() => {
    mediator.base.dispatch({
      type: "OPEN_LOGIN_SUCCESS",
      payload: { user, jurisdiction, isVerified }
    });
  });

  // Send AppsFlyer login event for iOS app
  sendToAppsFlyer({
    key: AppsFlyerEvents.AfLogin,
    values: {
      accountId: accountNumber
    }
  });

  mediator.ios.dispatch({
    type: "PUSHWOOSH_EVENTS",
    payload: {
      pushwooshTags: {
        HasLoggedInOnce:
          attempt(() => localStorage.getItem("userLoginOnce")) || false,
        StateOfRegistration: get(user, "homeAddress.state", ""),
        FirstName: get(user, "firstName", ""),
        AccountNumber: accountNumber
      }
    }
  });
};

const saveUserId = (userId: string) => {
  attempt(() => {
    window.sessionStorage.setItem("userId", userId);
  });
};

export const getFanduelUser = async (
  authToken: string
): Promise<Partial<UserInfo>> => {
  try {
    const response = await getFanduelUserProfile(authToken);
    const {
      username: userName,
      last_name: lastName,
      first_name: firstName,
      email
    } = get(response, "data.users[0]");

    return {
      userName,
      emailAddress: email,
      firstName: firstName || "",
      lastName: lastName || "",
      profile: "FDR-Generic",
      accountNumber: "",
      // TODO: verify if we can receive homeAddress from the endpoint
      homeAddress: { state: "" }
    };
  } catch (error) {
    console.warn("getFanduelUser Error:", error);
    throw error;
  }
};

export const getUserProfile = async (
  authToken: string,
  stateAbbr: string,
  dispatch?: Dispatch
): Promise<Partial<{ user: UserInfo; isVerified: boolean }>> => {
  const { userName, userEmail, fdUserId } = getUserSessionData(authToken);

  try {
    const { data } = await profileService.getFDRUserProfile();
    const isVerified = get(data, "isUserVerified", false) as boolean;

    if (
      (typeof data.unmetCriteria === "string" ||
        Array.isArray(data.unmetCriteria)) &&
      data.unmetCriteria.includes(INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE)
    ) {
      // eslint-disable-next-line
      throw INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE;
    }

    const user = {
      userName,
      emailAddress: userEmail,
      profile: get(data, "wagerProfile", "FDR-Generic"),
      accountNumber: String(get(data, "accountNumber", "")),
      firstName: get(data, "firstName", ""),
      lastName: get(data, "lastName", ""),
      homeAddress: { state: get(data, "state", "") },
      fdUserId,
      hasMadePreviousWager: get(data, "hasWagered", null)
    } as UserInfo;

    if (typeof dispatch === "function") {
      dispatch(
        setUserData({
          user
        } as User)
      );
    }

    return { user, isVerified };
  } catch (error) {
    if (
      get(error, "response.data.code") === TVG_ACCOUNT_ID_NOT_FOUND_EXCEPTION &&
      stateAbbr
    ) {
      try {
        await profileService.postFDRProfileMigration(
          fdUserId,
          stateAbbr,
          userEmail
        );

        if (typeof dispatch === "function") {
          dispatch(resetStateLocationSplashAction());
        }

        return getUserProfile(authToken, stateAbbr, dispatch);
      } catch (migrationError) {
        const errorCode = get(migrationError, "response.data.code");
        const errorMessage = get(migrationError, "response.data.message");
        if (
          (typeof errorMessage === "string" || Array.isArray(errorMessage)) &&
          errorMessage.includes(INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE)
        ) {
          // eslint-disable-next-line
          throw INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE;
        }

        if (errorCode === FDR_USER_NOT_VERIFIED_EXCEPTION) {
          const fdUserInfo = await getFanduelUser(authToken);
          if (typeof dispatch === "function") {
            dispatch(
              setUserData({
                user: fdUserInfo as unknown as UserInfo
              } as unknown as User)
            );
          }

          return { user: fdUserInfo as UserInfo, isVerified: false };
        }

        if (typeof dispatch === "function") {
          dispatch(fdrGhostAccountExceptionAction());
        }

        console.error("postFDRProfileMigration ERROR: ", migrationError);

        if (isXSell()) {
          throw error;
        }

        return {};
      }
    } else {
      throw error;
    }
  }
};

export const completeLogin = async (
  authToken: string,
  dispatch: Dispatch,
  stateAbbr: string,
  options: CompleteLoginExtraOptions = {
    userName: "",
    enforceAcceptTerms: false,
    isGhostAccountError: false
  }
) => {
  if (!options.isGhostAccountError) {
    dispatch(resetStateLocationSplashAction());
  }

  if (isXSell()) {
    await new Promise((r) => setTimeout(r, 0));
  }

  if (getAuthTokenCookie() !== authToken) {
    createAuthTokenCookie(authToken);
  }

  try {
    const [userProfile, jurisdiction] = await Promise.all([
      getUserProfile(authToken, stateAbbr, dispatch),
      getUserJurisdiction()
    ]);

    const user = get(userProfile, "user", {
      userName: "",
      profile: "",
      accountNumber: "",
      lastName: "",
      firstName: "",
      emailAddress: ""
    });
    const isVerified = get(userProfile, "isVerified", false);

    // TODO: confirm cookie values
    addCookie("wp", user.profile);
    addCookie("hasLoginOnce", "true", 31622400);
    saveUserId(user.accountNumber);

    setDatadogRumUserIfEnabled({
      userId: user.accountNumber,
      userName: user.userName,
      emailAddress: user.emailAddress
    });

    const payload = {
      user,
      logged: true,
      isVerified
    };

    if (isFdrProduct()) {
      mediator.base.dispatch({
        type: "FDR_LOGIN:USER_SESSION_UPDATE",
        payload: {
          ...payload
        }
      });
    } else {
      mediator.base.dispatch({
        type: "TVG_LOGIN:USER_SESSION_UPDATE",
        payload: {
          ...payload,
          hasRequested: true
        }
      });
    }

    handleLoginResponse(
      {
        status: "success",
        data: {
          user,
          jurisdiction,
          isVerified
        }
      },
      dispatch,
      options
    );
    dispatch(setRaceAlertsUserId(user.accountNumber));
  } catch (error) {
    if (error === INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE) {
      dispatch(openInvalidResidentialAddressModal());
    }
    if (
      !isXSell() &&
      get(error, "response.data.code") === FDR_EXPIRED_TOKEN_EXCEPTION
    ) {
      logout();
    }
    if (
      !isXSell() &&
      !options?.userName &&
      error !== INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE &&
      get(error, "response.data.code") !== FDR_EXPIRED_TOKEN_EXCEPTION
    ) {
      if (isFdrProduct()) {
        dispatch(errorLocationSplashAction("DEFAULT_ERROR"));
      } else {
        dispatch(openErrorModal());
        dispatch(setIsLoginErrorPage(true));
      }
    } else {
      throw error;
    }
  }
};

export const handleRefreshSession = async (
  authToken: string,
  stateAbbr: string,
  isLogged: boolean,
  dispatch: Dispatch,
  options: CompleteLoginExtraOptions = {
    userName: "",
    enforceAcceptTerms: false
  },
  enableRetryGeocomply?: boolean
) => {
  try {
    if (!isLogged && authToken) {
      await completeLogin(authToken, dispatch, stateAbbr, options);
    } else if (authToken !== getAuthTokenCookie()) {
      if (enableRetryGeocomply) {
        const { sessionId: newSessionId } = getUserSessionData(authToken);
        const { sessionId: oldSessionId } =
          getUserSessionData(getAuthTokenCookie());

        if (newSessionId !== oldSessionId) {
          onTriggerGeolocation("Retry");
        }
      }

      createAuthTokenCookie(authToken);
      await getUserProfile(authToken, stateAbbr, dispatch);
    }
  } catch (e) {
    console.error("Refresh Session:", e);

    if (isLogged) {
      logout();
    }
  }
};
