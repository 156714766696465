import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import StateSelector from "@urp/comp-state-selector";

import {
  clearSportsbookRegionCookie,
  createSportsbookRegionCookie,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";
import {
  setGeoLocationState,
  setShowStateSelector
} from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { resetStateLocationSplashAction } from "@urp/location-splash/src/actions";
import { completeFlow } from "@urp/location-splash";
import { getIsLogged } from "@urp/store-selectors";
import mediator from "@tvg/mediator";

const StateSelectorScreen = ({ currentPath, flowInProcess, onClose }) => {
  const dispatch = useDispatch();
  const userLogged = useSelector(getIsLogged);
  const showStateSelector = useSelector((store) =>
    get(store, "geolocation.showStateSelector", false)
  );
  const locationUnderFlow = useSelector((store) =>
    get(store, "locationSplash.locationUnderFlow")
  );
  const [element, setElement] = useState(null);
  const [root, setRoot] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setElement(document.createElement("div"));
      setRoot(document.body);
    }
  }, []);

  useEffect(() => {
    if (element && root) {
      root.appendChild(element);
    }
    return () => {
      if (root) {
        root.removeChild(element);
      }
    };
  }, [root, element]);

  useEffect(() => {
    const state = getSportsbookRegionCookie();
    if (userLogged) {
      dispatch(setGeoLocationState(state));
    } else if (!state) {
      clearSportsbookRegionCookie();
    }
  }, [userLogged]);

  const handleSelectedState = (state) => {
    createSportsbookRegionCookie(state.abbreviation);
    dispatch(setGeoLocationState(state.abbreviation));
    dispatch(setShowStateSelector(false));
    dispatch(resetStateLocationSplashAction());
    completeFlow(locationUnderFlow);
    mediator.base.dispatch({ type: "TRIGGER_CALLBACK" });
    if (flowInProcess) {
      mediator.base.dispatch(flowInProcess);
    }

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  return (
    showStateSelector &&
    element &&
    createPortal(
      <StateSelector
        onSelectState={handleSelectedState}
        to={currentPath}
        onClose={onClose}
      />,
      element
    )
  );
};

export default StateSelectorScreen;
