import tvgConf from "@tvg/conf";
import requester from "../requester";
import getProtocol from "../protocolSetter";
import { serviceResponse } from "../types";

const cusService: string = "service.cus";
const getCusServiceUrl = tvgConf().config(cusService);

/**
 * Gets the states of USA
 * @param origin - an string of "TVG" or "FDR" states, default is "TVG"
 * @param zipCode - an string to get the state of the passed zipCode
 */
const getStates = (
  origin?: "TVG" | "FDR",
  zipCode?: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${getCusServiceUrl}/states/`,
    method: "get",
    withCredentials: true,
    params: {
      origin,
      zipCode
    }
  });

const CUS_SERVICES = {
  getStates
};

export default CUS_SERVICES;
