import { get } from "lodash";
import { State, Actions, SplashType, LocationStatusTypes } from "./types";

export const initialState = {
  isOpen: false,
  isLoading: false,
  splashType: "ALLOW_LOCATION" as SplashType,
  error: null,
  isLocationRequired: false,
  isLocationBehindTheScenes: false,
  locationUnderLogin: "",
  status: "empty" as LocationStatusTypes,
  iOSForceUpdateFDR: {
    forceUpdate: false,
    minimumVersion: "0.0.0",
    forceUpdateAndroid: false,
    minimumVersionAndroid: "0.0.0",
    title: "",
    message: "",
    primaryButtonTitle: ""
  },
  globalFDRSupportLink: ""
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case "ANDROID_BLOCKED_STATE": {
      return {
        ...state,
        isOpen: true,
        isLocationRequired: true,
        splashType: "ANDROID_BLOCKED_STATE"
      };
    }
    case "CLEAR_ERROR_SPLASH": {
      return {
        ...initialState
      };
    }
    case "SET_LOADING_LOCATION_SPLASH":
      return {
        ...state,
        isOpen: true,
        isLoading: true,
        error: null
      };
    case "ALLOW_LOCATION_SPLASH":
      // If we want user to allow his location
      return {
        ...state,
        splashType: "ALLOW_LOCATION",
        isLocationRequired: true,
        isLocationBehindTheScenes: false,
        isOpen: get(action, "payload.isOpen", initialState.isOpen),
        isLoading: false,
        error: null,
        status: "pending"
      };
    case "SET_BLOCKED_COUNTRY": {
      return {
        ...state,
        isOpen: action.payload,
        isLocationRequired: action.payload,
        splashType: "BLOCKED_COUNTRY"
      };
    }
    case "SET_APP_VERSION_OUTDATED": {
      return {
        ...state,
        isOpen: true,
        splashType: "APP_VERSION_OUTDATED"
      };
    }
    case "ERROR_LOCATION_SPLASH":
      // Any kind of error in allow location splash
      return {
        ...state,
        isOpen: true,
        isLoading: false,
        splashType: "ERROR",
        error: get(action, "payload.error", initialState.error)
      };
    case "FDR_GHOST_ACCOUNT_EXCEPTION":
      return {
        ...state,
        isOpen: true,
        isLoading: false,
        splashType: "FDR_GHOST_ACCOUNT_EXCEPTION"
      };
    case "INVALID_WAGERING_STATE_LOCATION_SPLASH":
      // To show invalid wagering state splash
      return {
        ...state,
        isOpen: true,
        isLoading: false,
        splashType: "INVALID_WAGERING_STATE",
        error: null
      };
    case "SET_LOCATION_STATUS":
      return {
        ...state,
        status: get(action, "payload.status", initialState.status)
      };
    case "SET_LOCATION_BEHIND_SCENES":
      return {
        ...state,
        isLocationBehindTheScenes: get(
          action,
          "payload.isLocationBehindTheScenes",
          initialState.isLocationBehindTheScenes
        )
      };
    case "SET_LOCATION_REQUIRED":
      return {
        ...state,
        isLocationRequired: get(
          action,
          "payload.isLocationRequired",
          initialState.isLocationRequired
        )
      };
    case "SET_LOCATION_UNDER_FLOW":
      return {
        ...state,
        locationUnderFlow: get(action, "payload.locationUnderFlow")
      };
    case "RG_EXCLUSION_SPLASH":
      return {
        ...state,
        isOpen: true,
        isLoading: false,
        splashType: get(action, "payload.exclusionType"),
        exclusionEndDate: get(action, "payload.exclusionEndDate"),
        error: null
      };
    case "RESET_STATE":
      return {
        ...initialState,
        status: state.status,
        isLocationRequired: state.isLocationRequired
      };
    case "WALKTHROUGH_NOT_FOUND":
      return {
        ...state,
        isOpen: true,
        isLoading: false,
        splashType: "WALKTHROUGH_NOT_FOUND"
      };
    default: {
      return state;
    }
  }
};

export default reducer;
