import React, { useState, useEffect } from "react";
import { get, noop } from "lodash";
import { useSelector } from "react-redux";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { States } from "@tvg/ts-types/States";
// @ts-ignore
import { HomepageMask } from "@fdr/static-ui/Masks";
import tvgConf from "@tvg/conf";
import StateSelectorTemplate from "./components/StateSelector";
import { Props, CapiStatesType } from "./types";

const getStateList = (fdrStates: CapiStatesType) =>
  Object.entries(fdrStates).map(([abbreviation, name]) => ({
    abbreviation,
    name
  }));

const returnStateSelectorPath = (product: string) => {
  if (product === "fdrandroidgps") {
    return "capi.messages.stateSelectorListFdrAndroidGPS";
  }

  return "capi.messages.stateSelectorListFdr";
};

export const StateSelector = ({ onSelectState = noop, to, onClose }: Props) => {
  const [states, setStates] = useState<States[]>([]);
  const stateSelectorListFdr: CapiStatesType = useSelector((store) =>
    parseCapiMessage(store, returnStateSelectorPath(tvgConf().product), {})
  );
  const stateTitle = useSelector((store) =>
    get(store, "capi.messages.stateSelectorTitleFdr", "")
  );
  const stateDescription = useSelector((store) =>
    get(store, "capi.messages.stateSelectorDescriptionFdr", "")
  );

  const missingStateRedirect = useSelector((store) =>
    get(store, "capi.messages.missingStateRedirectURL", "")
  );

  useEffect(() => {
    setStates(getStateList(stateSelectorListFdr));
  }, [JSON.stringify(stateSelectorListFdr)]);

  useEffect(() => onClose, []);

  return states.length ? (
    <StateSelectorTemplate
      title={stateTitle}
      description={stateDescription}
      states={states}
      onSelectState={onSelectState}
      to={to}
      missingStateRedirect={missingStateRedirect}
    />
  ) : (
    <HomepageMask />
  );
};

export default StateSelector;
