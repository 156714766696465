import React from "react";

const ErrorPage = () => (
  <svg
    width="158"
    height="150"
    viewBox="0 0 158 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.259 6.82004C111.253 3.9657 116.005 3.71849 119.303 6.20761L119.514 6.37668L120.024 6.82004L150.114 35.5066C153.108 38.3609 153.367 42.8914 150.579 46.236L150.114 46.7222L118.354 77.0005L150.114 107.278C153.221 110.24 153.356 114.963 150.519 118.079L150.114 118.494L120.024 147.18C116.775 150.277 111.508 150.277 108.259 147.18L76.5004 116.902L44.7407 147.18C41.8828 149.905 37.4229 150.254 33.9722 147.968L33.4864 147.623L32.9762 147.18L2.88609 118.494C-0.107907 115.639 -0.367199 111.109 2.42105 107.764L2.88614 107.278L34.6436 77.0005L2.88609 46.7223C-0.221336 43.7598 -0.356443 39.0367 2.48077 35.9211L2.88609 35.5066L32.9762 6.82004C36.2249 3.7229 41.4921 3.7229 44.7407 6.82004L76.5004 37.0962L108.259 6.82004Z"
      fill="#152A47"
    />
    <path
      d="M113.259 2.82004C116.253 -0.0343015 121.005 -0.281507 124.303 2.20761L124.514 2.37668L125.024 2.82004L155.114 31.5066C158.108 34.3609 158.367 38.8914 155.579 42.236L155.114 42.7222L123.354 73.0005L155.114 103.278C158.221 106.24 158.356 110.963 155.519 114.079L155.114 114.494L125.024 143.18C121.775 146.277 116.508 146.277 113.259 143.18L81.5004 112.902L49.7407 143.18C46.8828 145.905 42.4229 146.254 38.9722 143.968L38.4864 143.623L37.9762 143.18L7.88609 114.494C4.89209 111.639 4.6328 107.109 7.42105 103.764L7.88614 103.278L39.6436 73.0005L7.88609 42.7223C4.77866 39.7598 4.64356 35.0367 7.48077 31.9211L7.88609 31.5066L37.9762 2.82004C41.2249 -0.277097 46.4921 -0.277097 49.7407 2.82004L81.5004 33.0962L113.259 2.82004Z"
      fill="#1F375B"
    />
    <path
      d="M113.259 2.82004C116.253 -0.0343015 121.005 -0.281507 124.303 2.20761L124.514 2.37668L125.024 2.82004L155.114 31.5066C158.108 34.3609 158.367 38.8914 155.579 42.236L155.114 42.7222L123.354 73.0005L155.114 103.278C158.221 106.24 158.356 110.963 155.519 114.079L155.114 114.494L125.024 143.18C121.775 146.277 116.508 146.277 113.259 143.18L81.5004 112.902L49.7407 143.18C46.8828 145.905 42.4229 146.254 38.9722 143.968L38.4864 143.623L37.9762 143.18L7.88609 114.494C4.89209 111.639 4.6328 107.109 7.42105 103.764L7.88614 103.278L39.6436 73.0005L7.88609 42.7223C4.77866 39.7598 4.64356 35.0367 7.48077 31.9211L7.88609 31.5066L37.9762 2.82004C41.2249 -0.277097 46.4921 -0.277097 49.7407 2.82004L81.5004 33.0962L113.259 2.82004ZM119.142 5.02902C118.229 5.02902 117.317 5.36086 116.621 6.02453L81.5004 39.5072L46.3794 6.02453C45.7828 5.45567 45.0272 5.1306 44.2489 5.04934L43.8585 5.02902C42.9461 5.02902 42.0337 5.36086 41.3375 6.02453L11.2474 34.7111C9.85509 36.0384 9.85509 38.1904 11.2474 39.5178L46.3684 73.0005L11.2474 106.482L10.9703 106.779C9.8622 108.113 9.95454 110.057 11.2474 111.289L41.3375 139.976L41.6485 140.24C42.2944 140.727 43.0764 140.971 43.8585 140.971L44.2489 140.951C45.0272 140.87 45.7828 140.544 46.3794 139.976L81.5004 106.494L116.621 139.976C117.317 140.639 118.229 140.971 119.142 140.971L119.532 140.951C120.31 140.87 121.066 140.544 121.662 139.976L151.753 111.289C153.145 109.962 153.145 107.81 151.753 106.482L116.632 73.0005L151.753 39.5178L152.03 39.2213C153.138 37.8872 153.045 35.9436 151.753 34.7111L121.662 6.02453L121.352 5.76041C120.813 5.35408 120.18 5.11706 119.532 5.04934L119.142 5.02902Z"
      fill="url(#paint0_linear_35742_72229)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_35742_72229"
        x1="44.4423"
        y1="5.93183"
        x2="126.949"
        y2="184.656"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#887AEF" />
        <stop offset="1" stopColor="#1493FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default ErrorPage;
