import mediator from "@tvg/mediator";
import {
  GeolocationCallbacks,
  GeoLocationConfig,
  GeoLocationLicenseType
} from "../solus/types/solus";
import { useLocateUser } from "../hooks/useLocateUser";
import { GeoComplyEvents } from "./types";
import { useGeoComplyRetry } from "../hooks/useGeoComplyRetry";
import { GeolocationRetryState } from "../types";

const geoComplyEvent = (event: GeoComplyEvents, payload = {}) => {
  mediator.base.dispatch({
    type: event,
    payload
  });
};

export const startGeoComply = (
  state: string,
  isLogged: boolean,
  enableEngine: boolean,
  retryState: GeolocationRetryState = GeolocationRetryState.IDLE
) => {
  const config: GeoLocationConfig = {
    // For Racing we need to force "NJ" State in GeoComply
    // This will only be used to obtain the license
    state: "NJ",
    product: "RACING",
    licenseType: GeoLocationLicenseType.SOLUS
  };
  const callbacks: GeolocationCallbacks = {
    onFailed: (failure) => {
      geoComplyEvent(GeoComplyEvents.FAILURE, {
        geoErrorName: failure.message,
        geoErrorCode: failure.code
      });
    },
    onSuccess: (geolocation, geoToken) => {
      geoComplyEvent(GeoComplyEvents.VERIFIED, {
        geoToken,
        region: geolocation.region_code
      });
    },
    onRetry: () => {
      geoComplyEvent(GeoComplyEvents.RETRY);
    },
    onReject: (geolocation) => {
      geoComplyEvent(GeoComplyEvents.REJECT, {
        troubleshooters: geolocation.troubleshooter,
        region: geolocation.region_code
      });
    },
    onRefresh: (geolocation, geoToken) => {
      geoComplyEvent(GeoComplyEvents.REFRESH, {
        geoToken,
        region: geolocation.region_code
      });
    },
    onProcess: () => {
      geoComplyEvent(GeoComplyEvents.REQUEST);
    }
  };

  const { retryLocateUser, retryAttempts } = useLocateUser(
    state,
    isLogged,
    config,
    callbacks,
    enableEngine
  );
  useGeoComplyRetry(retryLocateUser, {
    state: retryState,
    attempts: retryAttempts
  });
};
