import { useCallback, useEffect, useState } from "react";
import { get, noop } from "lodash";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { GeoLocationResponseSuccess } from "@fdr/types/ts/GeoLocation";
import {
  getAuthTokenCookie,
  getUserSessionData
} from "@tvg/sh-utils/sessionUtils";
import {
  GeoClientTokenStatus,
  GeolocationCallbacks,
  GeolocationCallbackSuccess,
  GeoLocationConfig,
  GeolocationReason,
  GeolocationSession
} from "../solus/types/solus";
import {
  getCacheGeoPacket,
  getGeoExpiryTime,
  getPreviousTokenStatus
} from "../solus/utils";
import { useGeoClientHandlers } from "./useGeoClientHandlers";
import { useGeoSession } from "./useGeoSession";
import { locateUser } from "..";

const checkCanVerify = (
  geoState: string,
  geoSession: GeolocationSession,
  geoConfig: GeoLocationConfig
) =>
  geoState &&
  geoSession &&
  geoSession.userId &&
  geoSession.sessionId &&
  geoConfig &&
  geoConfig.state;

const verifyUserLocation = (
  geoState: string,
  geoSession: GeolocationSession,
  geoConfig: GeoLocationConfig,
  geoCallbacks: GeolocationCallbacks,
  reason: GeolocationReason,
  previousTokenStatus: GeoClientTokenStatus,
  userVerifiedSuccessCallback: NullaryFn<void> = noop
) => {
  if (checkCanVerify(geoState, geoSession, geoConfig)) {
    locateUser(
      geoState,
      reason,
      geoSession,
      geoConfig,
      geoCallbacks,
      previousTokenStatus
    );
    userVerifiedSuccessCallback();
  }
};

export const useLocateUser = (
  geoState: string,
  userIsLogged: boolean,
  geoConfig: GeoLocationConfig,
  geoCallbacks: GeolocationCallbacks = {
    onFailed: noop,
    onReject: noop,
    onProcess: noop,
    onSuccess: noop,
    onRefresh: noop,
    onRetry: noop
  },
  enableEngine: boolean
) => {
  const [reason, setReason] = useState(GeolocationReason.LOGIN);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [previousTokenStatus, setPreviousStatus] = useState(
    getPreviousTokenStatus()
  );
  const geoSession = useGeoSession(userIsLogged);
  const { actions, isProcessing, canRetry, retryAttempts } =
    useGeoClientHandlers({
      ...geoCallbacks,
      onSuccess: (geoPacket, geoToken) => {
        setPreviousStatus(getPreviousTokenStatus(geoPacket.result));
        geoCallbacks.onSuccess(geoPacket, geoToken);
      },
      onRefresh: (geoPacket) => {
        periodicVerifyLocation(geoPacket, geoCallbacks.onRefresh);
      },
      onRetry: (currentRetryAttempts) => {
        const authToken = getAuthTokenCookie();
        const { fdUserId, sessionId } = getUserSessionData(authToken);

        const userSession = {
          userId: fdUserId,
          sessionId,
          userAuthToken: authToken
        };

        verifyUserLocation(
          geoState,
          userSession,
          geoConfig,
          actions,
          reason,
          previousTokenStatus,
          () => {
            geoCallbacks.onRetry(currentRetryAttempts);
          }
        );
      }
    });
  const periodicVerifyLocation = useCallback(
    (
      geoPacket: GeoLocationResponseSuccess,
      refreshCallback: GeolocationCallbackSuccess
    ) => {
      if (enableEngine) {
        const geolocateIn = get(geoPacket, "geolocate_in");
        if (geolocateIn) {
          if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
          }

          const pollerTime = getGeoExpiryTime(geolocateIn);
          setIntervalId(
            setInterval(() => {
              setReason(GeolocationReason.PERIODIC); // default geocomply reason in this case is PERIODIC
              refreshCallback(geoPacket, getCacheGeoPacket() || "");
              verifyUserLocation(
                geoState,
                geoSession,
                geoConfig,
                actions,
                reason,
                previousTokenStatus
              );
            }, pollerTime)
          );
        }
      } else if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    },
    [intervalId]
  );

  const retryLocateUser = () => {
    if (canRetry) {
      actions.onRetry(retryAttempts + 1);
    } else {
      console.warn("This rejection is not allowed retry.");
    }
  };

  useEffect(() => {
    if (enableEngine) {
      verifyUserLocation(
        geoState,
        geoSession,
        geoConfig,
        actions,
        reason,
        previousTokenStatus
      );
    }
  }, [enableEngine, JSON.stringify(geoSession), JSON.stringify(geoConfig)]);

  return {
    retryLocateUser,
    retryAttempts,
    canRetry,
    isProcessing
  };
};
