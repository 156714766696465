import React, { Fragment } from "react";
import Icon from "@fdr/static-ui/Icons";
import allIcons, {
  crossCircle,
  warning,
  location,
  clock,
  calendar,
  lock,
  noWifi
} from "@fdr/static-ui/Icons/icons";
import buildColor from "@fdr/static-ui/ColorPalette";
import BlockedCountrySVG from "@fdr/static-ui/SvgImages/BlockedCountrySVG";
import AndroidBlockedStateSVG from "@fdr/static-ui/SvgImages/AndroidBlockedStateSVG";
import {
  Message,
  IconWrapper,
  Title,
  MessageText,
  AndroidBlockedTitle,
  AndroidBlockedMessageText
} from "./styled-components";

const getIconType = (iconType) => {
  switch (iconType) {
    case "error":
      return crossCircle;
    case "warningRed":
      return warning;
    case "warningRosa":
      return warning;
    case "allowRed":
      return location;
    case "warningBlue":
      return warning;
    case "allow":
      return location;
    case "clock":
      return clock;
    case "calendar":
      return calendar;
    case "lock":
      return lock;
    case "noWifi":
      return noWifi;
    default:
      return allIcons[iconType] || location;
  }
};

const getIcon = (iconType, qaLabel) => {
  switch (iconType) {
    case "error":
    case "warningRed":
    case "allowRed":
      return (
        <IconWrapper type="error">
          <Icon
            icon={getIconType(iconType)}
            color={buildColor("white", "100")}
            size={40}
            qaLabel={`${qaLabel}CenterIcon`}
          />
        </IconWrapper>
      );
    case "warningRosa":
      return (
        <IconWrapper type="warningRosa">
          <Icon
            icon={getIconType(iconType)}
            color={buildColor("red", "500")}
            size={40}
            qaLabel={`${qaLabel}CenterIcon`}
          />
        </IconWrapper>
      );
    default:
      return (
        <IconWrapper type="normal">
          <Icon
            icon={getIconType(iconType)}
            color={buildColor("white", "100")}
            size={40}
            qaLabel={`${qaLabel}CenterIcon`}
          />
        </IconWrapper>
      );
  }
};

export const renderLoginMessage = ({
  iconType,
  iconComponent,
  title,
  Text,
  qaLabel,
  textType,
  isMigration,
  isInjection,
  colorTitle,
  colorText,
  isAndroidBlocked
}) => (
  <Fragment>
    {!isMigration &&
      !isAndroidBlocked &&
      (iconComponent || getIcon(iconType, qaLabel))}
    <Title colorTitle={colorTitle} data-qa-label={`${qaLabel}Title`}>
      {title}
    </Title>
    {isInjection ? (
      <MessageText
        data-qa-label={`${qaLabel}-message-text`}
        dangerouslySetInnerHTML={{ __html: Text }}
      />
    ) : (
      <MessageText
        data-qa-label={`${qaLabel}-message-text`}
        colorText={colorText}
      >
        {textType === "text" ? Text : <Text />}
      </MessageText>
    )}
  </Fragment>
);

const getAndroidBlockedLogo = (androidBlockType) => {
  switch (androidBlockType) {
    case "state":
      return <AndroidBlockedStateSVG />;
    case "country":
      return <BlockedCountrySVG />;
    default:
      return <BlockedCountrySVG />;
  }
};

export const renderAndroidBlocked = ({
  title,
  Text,
  qaLabel,
  androidBlockType
}) => (
  <Fragment>
    {getAndroidBlockedLogo(androidBlockType)}
    <AndroidBlockedTitle data-qa-label={`${qaLabel}Title`}>
      {title}
    </AndroidBlockedTitle>
    <AndroidBlockedMessageText
      data-qa-label={`${qaLabel}-message-text`}
      dangerouslySetInnerHTML={{ __html: Text }}
    />
  </Fragment>
);

// @TODO change message text to set inner html
const LoginMessage = (props) => {
  return (
    <Message data-qa-label={`${props.qaLabel}-message-info`}>
      {props.isAndroidBlocked
        ? renderAndroidBlocked(props)
        : renderLoginMessage(props)}
    </Message>
  );
};

LoginMessage.defaultProps = {
  iconType: "allow",
  iconComponent: null,
  title: "",
  Text: null,
  qaLabel: "",
  textType: "text",
  isMigration: false,
  isInjection: false,
  colorTitle: buildColor("white", "100"),
  colorText: buildColor("grey", "300"),
  isAndroidBlocked: false
};

export default React.memo(LoginMessage);
