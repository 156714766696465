import { parseISO, isSameDay } from "date-fns";
import { getUserJurisdictions as getUserJurisdictionsService } from "@tvg/api/aw";
import {
  UserJurisdictionRow,
  TermsAndConditions,
  Acknowledgement,
  UserJurisdiction
} from "@tvg/api/aw/types";
import {
  getAuthTokenCookie,
  getUserSessionData,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";

export const termsAndConditionsAcceptance = (
  termsAndConditions: TermsAndConditions
): boolean => {
  const latestVersion = parseISO(termsAndConditions.latest_version);
  const lastAcceptedVersion = parseISO(
    termsAndConditions.last_accepted_version
  );
  return isSameDay(latestVersion, lastAcceptedVersion);
};

export const acknowledgementsAcceptance = (
  acknowledgements: Acknowledgement[]
): boolean =>
  acknowledgements.every((item: Acknowledgement) => item.acknowledged);

export const getUserJurisdiction =
  async (): Promise<UserJurisdiction | null> => {
    const authToken = getAuthTokenCookie();
    const region = getSportsbookRegionCookie();
    const { fdUserId } = getUserSessionData(authToken);

    const jurisdictionsDataset = await getUserJurisdictionsService(
      authToken,
      fdUserId,
      region
    );

    const userJurisdiction = jurisdictionsDataset.find(
      (item: UserJurisdictionRow) => item.jurisdiction === region
    );

    if (userJurisdiction) {
      const isTCAccepted = termsAndConditionsAcceptance(
        userJurisdiction.terms_and_conditions
      );

      const isACAccepted = acknowledgementsAcceptance(
        userJurisdiction.acknowledgements
      );

      return { ...userJurisdiction, accepted: isTCAccepted && isACAccepted };
    }

    return null;
  };
