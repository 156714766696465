import styled from "styled-components";
import { fontNormal, fontBold, fontCondensed } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";
import Icon from "@fdr/static-ui/Icons/";

export const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 12px;
  min-height: 64px;
  background-color: ${buildColor("white", "100")};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoMessageWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

export const BoxSubContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0 20px;
  margin-bottom: 4px;

  &:last-of-type {
    border: none;
    padding-top: 0;
    padding-bottom: 29px;
  }

  &:last-of-type::after {
    content: "";
    position: absolute;
    width: calc(100% + 24px);
    height: 1px;
    background-color: ${buildColor("grey", "300")};
    bottom: 0;
    left: -12px;
    margin-bottom: 10px;
  }
`;

export const BoxSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
`;

export const BoxSubTitle = styled.h2`
  color: ${buildColor("navy", "800")};
  font-family: ${fontBold};
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 2px;
`;

export const BoxText = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  letter-spacing: 0;
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  height: 42px;
  border-radius: ${({ stateError }) => (stateError ? "3px 3px 0 0" : "3px")};
  border: 1px solid
    ${({ stateError }) =>
      stateError ? buildColor("red", "500") : buildColor("grey", "300")};
  margin-bottom: ${({ stateError }) => (stateError ? "0" : "12px")};
  cursor: pointer;
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 16px;
  cursor: pointer;
  font-family: ${fontNormal};
  font-size: 14px;
  font-weight: 400;
  color: ${({ state }) =>
    !state ? buildColor("grey", "800") : buildColor("grey", "900")};
  background-color: ${buildColor("white", "00")};
`;

export const ArrowDown = styled(Icon)`
  position: absolute;
  top: 13px;
  right: 8px;
`;

export const StateError = styled.div`
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 0 0 3px 3px;
  border: 1px solid ${buildColor("red", "500")};
  border-top: none;
  background-color: ${buildColor("red", "000")};
  color: ${buildColor("red", "700")};
  font-size: 12px;
  font-family: ${fontNormal};
  font-weight: 400;
  padding-left: 16px;
  margin-bottom: 12px;
`;

export const BalanceContainer = styled.div`
  background-color: transparent;
  position: relative;
  padding: ${({ isLowBalance }) =>
    isLowBalance ? "20px 0 20px 0" : "8px 0 20px 0"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: -12px;
    height: 1px;
    width: calc(100% + 24px);
    background-color: ${buildColor("grey", "300")};
  }
`;

export const BalanceDescription = styled.span`
  color: ${buildColor("grey", "800")};
  font-family: ${fontCondensed};
  font-size: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

export const BalanceValue = styled.span`
  color: ${buildColor("navy", "900")};
  font-family: ${fontBold};
  font-size: 16px;
`;
