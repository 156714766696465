import { useEffect, useState } from "react";
import {
  getAuthTokenCookie,
  getUserSessionData
} from "@tvg/sh-utils/sessionUtils";
import { cleanCacheGeoData } from "../solus/utils";
import { GeolocationSession } from "../solus/types/solus";

export const useGeoSession = (isLogged: boolean) => {
  const [geoSession, setGeoSession] = useState<GeolocationSession>({
    userId: "",
    sessionId: "",
    userAuthToken: ""
  });

  useEffect(() => {
    const userSessionCachedData = getAuthTokenCookie();
    if (isLogged) {
      const { fdUserId, sessionId } = getUserSessionData(userSessionCachedData);
      setGeoSession({
        userId: fdUserId,
        sessionId,
        userAuthToken: userSessionCachedData
      });
    } else if (!userSessionCachedData) {
      cleanCacheGeoData();
      setGeoSession({
        userId: "",
        sessionId: "",
        userAuthToken: ""
      });
    }
  }, [isLogged]);

  return geoSession;
};
