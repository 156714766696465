import { useState } from "react";
import { get, noop } from "lodash";
import {
  GeoLocationResponseRejected,
  GeoLocationResponseSuccess
} from "@fdr/types/ts/GeoLocation";
import {
  GeolocationCallbacks,
  GeolocationFailureResponse
} from "../solus/types/solus";

interface GeoClientHandlers {
  actions: GeolocationCallbacks;
  isProcessing: boolean;
  canRetry: boolean;
  retryAttempts: number;
}

export const useGeoClientHandlers = (
  geoCallbacks: GeolocationCallbacks
): GeoClientHandlers => {
  const [isProcessing, setProcessing] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [canRetry, setCanRetry] = useState(true);

  const {
    onFailed = noop,
    onReject = noop,
    onProcess = noop,
    onSuccess = noop,
    onRetry = noop,
    onRefresh = noop
  } = geoCallbacks;

  const onGeoProcess = (newProcessingStatus: boolean = true) => {
    setProcessing(newProcessingStatus);
    onProcess(newProcessingStatus);
  };

  const onGeoFailed = (failure: GeolocationFailureResponse) => {
    onGeoProcess(false);
    onFailed(failure);
  };

  const onGeoReject = (
    rejection: GeoLocationResponseRejected,
    geolocationToken: string
  ) => {
    onGeoProcess(false);
    setCanRetry(rejection.troubleshooter.every((error) => error.retry));
    onReject(rejection, geolocationToken);
  };

  const onGeoRefresh = (
    geoPacket: GeoLocationResponseSuccess,
    geolocationToken: string
  ) => {
    const geolocateIn = get(geoPacket, "geolocate_in");
    if (geolocateIn) {
      onGeoProcess(false);
      setRetryAttempts(0);
      onRefresh(geoPacket, geolocationToken);
    } else {
      console.error("geolocate_in is missing inside geo packet");
    }
  };

  const onGeoSuccess = (
    geoPacket: GeoLocationResponseSuccess,
    geolocationToken: string
  ) => {
    onGeoProcess(false);
    setRetryAttempts(0);
    const geolocateIn = get(geoPacket, "geolocate_in");
    if (geolocateIn) {
      onRefresh(geoPacket, geolocationToken);
    }
    onSuccess(geoPacket, geolocationToken);
  };

  const onGeoRetry = () => {
    const currentRetryAttempts = retryAttempts + 1;
    onGeoProcess(true);
    setRetryAttempts(currentRetryAttempts);
    onRetry(currentRetryAttempts);
  };

  return {
    actions: {
      onFailed: onGeoFailed,
      onReject: onGeoReject,
      onSuccess: onGeoSuccess,
      onProcess: onGeoProcess,
      onRetry: onGeoRetry,
      onRefresh: onGeoRefresh
    },
    isProcessing,
    canRetry,
    retryAttempts
  };
};
