import React from "react";
import { startGeoComply } from "../utils/startGeoComply";
import { GeolocationRetryState } from "../types";

interface Props {
  geoState: string;
  isLogged: boolean;
  geoComplyRetryStatus: GeolocationRetryState;
  isApp?: boolean;
}

export const StartGeoComply = ({
  geoState,
  isLogged,
  geoComplyRetryStatus,
  isApp = false
}: Props) => {
  // Start GeoComply Engine to fire events for GeoComply subscribers.
  startGeoComply(geoState, isLogged, !isApp, geoComplyRetryStatus);

  return <></>;
};
