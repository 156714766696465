import styled, { css } from "styled-components";
import { transparentize } from "polished";

import { buildText } from "@fdr/atomic-ui/_atoms/Text";
import buildColor from "../ColorPalette";

export const Overlay = styled.div`
  position: ${({ isFullScreen }) => (isFullScreen ? "fixed" : "absolute")};
  inset: ${(props) => `${props.top}px`} 0 0 0;
  background-color: ${(props) =>
    props.isTransparent
      ? transparentize(0.05, buildColor("white", "100"))
      : buildColor("white", "100")};
  background-color: ${({ isBlackTransparent }) =>
    isBlackTransparent &&
    css`
      ${transparentize(1, buildColor("black", "100"))}
    `};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${({ isFullScreen }) => (isFullScreen ? 99999 : 100)};
  ${(props) => props.rounded && "border-radius: 10px;"};
`;

export const Title = styled(
  buildText({
    tag: "span",
    fontSize: 12,
    color: buildColor("grey", "800")
  })
)`
  margin-top: 9px;
`;

export const Loading = styled.div`
  & span {
    display: inline-flex;
    height: ${(props) => `${props.spanSize}px`};
    width: ${(props) => `${props.spanSize}px`};
    background: ${(props) =>
      buildColor("blue", `${props.isURP ? "900" : "700"}`)};
    border-radius: 50%;
    opacity: 0.3;

    & :nth-child(1) {
      animation: dot1 0.5s infinite;
    }

    & :nth-child(2) {
      animation: dot2 0.5s infinite;
    }

    & :nth-child(3) {
      animation: dot3 0.5s infinite;
    }

    & :not(:last-child) {
      margin-right: 10px;
    }
  }

  @keyframes dot1 {
    0% {
      opacity: 0.3;
    }

    15% {
      opacity: 0.8;
    }

    33% {
      opacity: 1;
    }
  }

  @keyframes dot2 {
    33% {
      opacity: 0.3;
    }

    45% {
      opacity: 0.8;
    }

    66% {
      opacity: 1;
    }
  }

  @keyframes dot3 {
    66% {
      opacity: 0.3;
    }

    75% {
      opacity: 0.8;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Text = styled.p`
  color: ${({ isBlackTransparent }) =>
    !isBlackTransparent
      ? buildColor("navy", "900")
      : buildColor("grey", "300")};
  ${({ smallText }) =>
    smallText
      ? css`
          font-size: 14px;
          margin-top: 8px;
        `
      : css`
          font-size: 16px;
          margin-top: 16px;
        `};
  font-weight: 400;
  letter-spacing: 0;
  padding: 0 16px;
`;
