interface PerformanceUtils {
  register(mark: string, timing?: null | number | Date): void;
  measure(mark: string, tags?: Record<string, string>): void;
}

export default {
  register: (mark, timing = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.register(mark, timing);
    }
  },
  measure: (mark, tags) => {
    if (typeof window === "object" && window.perf) {
      window.perf.measure(mark, tags);
    }
  }
} as PerformanceUtils;
