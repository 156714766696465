import { Dispatch } from "redux";
import decodeJWT from "jwt-decode";
import { get } from "lodash";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import {
  setGeoLocationState,
  resetGeoLocation
} from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import {
  isXSell,
  subscribeGeolocationRequest,
  subscribeGeolocationUpdate,
  subscribeLocationFailure,
  subscribeLocationRejected,
  subscribeGeolocationVerified,
  subscribeGeolocationRetry
} from "@tvg/sh-utils/mobileUtils";
import {
  checkValidWageringState,
  createSportsbookRegionCookie,
  createGeoTokenCookie,
  getSportsbookRegionCookie,
  getGeoTokenCookie,
  clearGeoTokenCookie
} from "@tvg/sh-utils/sessionUtils";
import {
  setGeolocationError,
  geolocationMessageShown,
  setGeolocationStatus,
  tryGeolocationRetry,
  geolocationRetryClear,
  geolocationStatusClear
} from "../redux/actions";
import { GeolocationStatus, GeolocationRejectedErrorMessage } from "../types";
import { GeolocationCustomErrorMessages } from "../solus/types/solus";
import { handle2FAStateMismatch } from "./userSession";
import {
  GeoComplyDynamicValues,
  GeoComplyGPSInvalidStateMessage
} from "./types";
import { startMockTool } from "./mockTool";

const toastAnimationDuration = 350;

export const setUserState = (
  region: string,
  dispatch: Dispatch,
  {
    twoFactorAuthStates = [],
    wageringStates = []
  }: Partial<GeoComplyDynamicValues>
) => {
  const isWageringState = checkValidWageringState(region, wageringStates);
  if (isWageringState) {
    const cachedSportsbookRegionCookie = getSportsbookRegionCookie();

    handle2FAStateMismatch(region, twoFactorAuthStates);
    createSportsbookRegionCookie(region.toUpperCase());
    dispatch(setGeoLocationState(region.toUpperCase()));

    if (!isXSell() && cachedSportsbookRegionCookie !== region.toUpperCase()) {
      mediator.base.dispatch({ type: "GET_USER_JURISDICTION" });
    }
  }
};

export const setGeoLocationStatusCallback = (
  dispatch: Dispatch,
  status: GeolocationStatus
) => {
  dispatch(setGeolocationStatus(status));
  if (status === GeolocationStatus.PROGRESS) {
    setTimeout(
      () => dispatch(geolocationMessageShown(true)),
      toastAnimationDuration
    );
  }
};

const setLocationRejected =
  (dispatch: Dispatch, dynamicValues: Partial<GeoComplyDynamicValues>) =>
  (troubleshooters: GeolocationRejectedErrorMessage[], region: string) => {
    const isErrorRetryable = troubleshooters?.every(
      (error: GeolocationRejectedErrorMessage) => error.retry
    );
    setGeoLocationStatusCallback(dispatch, GeolocationStatus.REJECTED);
    dispatch(
      setGeolocationError(
        "GEOLOCATION_REJECTED",
        troubleshooters,
        !!isErrorRetryable
      )
    );
    setUserState(region, dispatch, dynamicValues);
  };

export const setGeolocationVerifiedCallback =
  (
    dispatch: Dispatch,
    dynamicValues: Partial<GeoComplyDynamicValues>,
    geolocationGPSInvalidMessage: GeoComplyGPSInvalidStateMessage,
    forceGeolocation?: Function
  ) =>
  (gcToken: string, region: string) => {
    if (forceGeolocation?.()) return;

    if (
      tvgConf().product === "fdrandroidgps" &&
      !dynamicValues.wageringStates?.includes(region)
    ) {
      const troubleshooters = [
        {
          message: geolocationGPSInvalidMessage.text,
          rule: "",
          title: geolocationGPSInvalidMessage.title
        }
      ] as GeolocationRejectedErrorMessage[];

      setLocationRejected(dispatch, dynamicValues)(troubleshooters, region);
      return;
    }
    dispatch(geolocationStatusClear());
    createGeoTokenCookie(gcToken);
    setUserState(region, dispatch, dynamicValues);
    setGeoLocationStatusCallback(dispatch, GeolocationStatus.PASSED);
    dispatch(geolocationRetryClear());
  };

export const setGeolocationFailure =
  (dispatch: Dispatch, forceGeolocation?: Function) => (error: string) => {
    if (forceGeolocation?.()) return;

    const messageError = [{ message: error?.toLowerCase() }];
    const retryableErrors = ["fetchlicense", "refreshlicense", "submit"];
    const isErrorRetryable = messageError.some((err) =>
      retryableErrors.includes(err.message)
    );

    if (
      error === GeolocationCustomErrorMessages.UNAUTHORIZED_LOCATION_PERMISSION
    ) {
      dispatch(resetGeoLocation());
      mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });
    }

    if (getGeoTokenCookie()) {
      clearGeoTokenCookie();
    }

    setGeoLocationStatusCallback(dispatch, GeolocationStatus.FAILURE);
    dispatch(setGeolocationError("LOCATION_FAILURE", error, isErrorRetryable));
  };

export const geoComplySubscriptions = (
  dispatch: Dispatch,
  dynamicValues: Partial<GeoComplyDynamicValues> = {
    wageringStates: [],
    twoFactorAuthStates: []
  },
  geolocationGPSInvalidMessage: GeoComplyGPSInvalidStateMessage
) => {
  let forceGeolocation: Function | undefined;

  // Restrict geocomply mock tool only to QA environment
  if (tvgConf().environment === "qa") {
    forceGeolocation = startMockTool(
      dispatch,
      dynamicValues,
      geolocationGPSInvalidMessage,
      setGeolocationVerifiedCallback,
      setLocationRejected,
      setGeolocationFailure
    );
  }

  subscribeGeolocationRequest(() => {
    dispatch(tryGeolocationRetry(false));
    setGeoLocationStatusCallback(dispatch, GeolocationStatus.PROGRESS);
  });

  subscribeGeolocationVerified(
    setGeolocationVerifiedCallback(
      dispatch,
      dynamicValues,
      geolocationGPSInvalidMessage,
      forceGeolocation
    )
  );

  subscribeLocationRejected(setLocationRejected(dispatch, dynamicValues));

  subscribeLocationFailure(setGeolocationFailure(dispatch, forceGeolocation));

  subscribeGeolocationUpdate((gcToken?: string) => {
    if (gcToken) {
      setGeolocationVerifiedCallback(
        dispatch,
        dynamicValues,
        geolocationGPSInvalidMessage,
        forceGeolocation
      )(gcToken, get(decodeJWT(gcToken), "region_code", ""));
    } else {
      const error = "unknown";
      setGeolocationFailure(dispatch, forceGeolocation)(error);
    }
  });

  subscribeGeolocationRetry(() => {
    dispatch(tryGeolocationRetry(true));
  });
};
