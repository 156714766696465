import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontCondensed, fontNormal } from "@fdr/static-ui/Typography";
import buttonDefault from "../../_atoms/ButtonDefault";

export const SelectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlataformPickerTitle = styled.span`
  color: ${buildColor("white", "100")};
  font-size: 14px;
  font-family: ${fontCondensed};
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
`;

export const PlatformPicker = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PlatformContainer = styled(buttonDefault)`
  width: 100%;
  position: relative;
  height: 122px;
  border-radius: 4px;
  border: 1px solid ${buildColor("navy", "500")};
  display: flex;
  flex-direction: column;

  &:focus,
  &:active > div:last-child {
    background-color: ${buildColor("navy", "500")};
  }

  &:focus,
  &:active > div:first-child {
    background-color: ${buildColor("navy", "700")};
  }

  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }
`;

export const BrandImageWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: ${buildColor("navy", "800")};
  padding: 16px 0;
  ${({ isTvg }) =>
    isTvg &&
    css`
      & > svg {
        transform: scale(1.5);
      }
    `}
`;

export const BrandFooter = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 4px 4px;
  justify-content: space-between;
  padding: 12px;
  background-color: ${buildColor("navy", "900")};
  border-top: 1px solid ${buildColor("navy", "500")};
`;

export const Brand = styled.span`
  color: ${buildColor("white", "100")};
  font-size: 14px;
  font-family: ${fontNormal};
`;
