import styled from "styled-components";
import { fontBold, fontNormal } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const Message = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: ${({ type }) => {
    switch (type) {
      case "error":
        return buildColor("red", "800");
      case "warningRosa":
        return buildColor("red", "000");
      default:
        return buildColor("navy", "900");
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled.h1`
  font-family: ${fontBold};
  font-weight: 500;
  color: ${(props) => props.colorTitle};
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 8px;
  padding: 0 28px;
`;

export const MessageText = styled.p`
  padding: 0 28px;
  color: ${(props) => props.colorText || buildColor("grey", "300")};
  font-size: 14px;
  font-family: ${fontNormal};
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
`;

export const AndroidBlockedTitle = styled.h1`
  font-family: ${fontNormal};
  font-weight: 500;
  color: ${buildColor("navy", "600")};
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 24px;
  padding: 0 28px;
`;

export const AndroidBlockedMessageText = styled.p`
  padding: 0 28px;
  color: ${buildColor("grey", "700")};
  font-size: 16px;
  line-height: 19px;
  font-family: ${fontNormal};
  text-align: center;
`;
