import styled from "styled-components";
import { rgba } from "polished";

export const StateSelectorBackground = styled.div<{ isDesktop: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) =>
    rgba(theme.colorTokens.background.secondary, 0.57)};
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isDesktop }) => isDesktop && `padding: 32px;`}
`;

export const StateSelectorWrapper = styled.section<{ isDesktop: boolean }>`
  top: 48px;
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  overflow-y: scroll;
  position: relative;
  ${({ isDesktop }) =>
    isDesktop &&
    `
      top: 24px;
      width: 450px;
      height: 652px;
      padding-bottom: 0;
    `}
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 125%;
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colorTokens.content.strong};
`;

export const Description = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 125%;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colorTokens.content.default};
`;

export const MenuItemsGroup = styled.ul`
  border: 1px solid ${({ theme }) => `${theme.colorTokens.border.default}`};
  border-radius: 4px;
  list-style: none;
  margin: 16px;
`;

export const MenuItem = styled.li`
  display: flex;
  flex-direction: column;
  min-height: 48px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 16px;
    width: calc(100% - 16px);
    height: 100%;
    box-shadow: inset 0 -1px 0 ${({ theme }) => `${theme.colorTokens.border.default}`};
    pointer-events: none;
  }

  & > a {
    flex: 1;
    padding: 12px 16px;

    & > svg {
      fill: ${({ theme }) => theme.colorTokens.content.link};
    }
  }

  &:last-of-type {
    box-shadow: none;
  }

  &:last-of-type::before {
    box-shadow: none;
  }
`;

export const Header = styled.div<{ isDesktop: boolean }>`
  position: fixed;
  width: 100%;
  top: 0;
  height: 48px;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colorTokens.background.promotional};
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isDesktop }) =>
    isDesktop &&
    `
      margin-top: -48px;
      top: auto;
      width: 450px;
    `}
`;

export const ButtonIconContainer = styled.button`
  background-color: transparent;
  cursor: pointer;
  align-self: center;
`;

export const CloseStateSelector = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.background.layer};
  display: flex;
  justify-content: center;
  padding: 16px 16px 32px 16px;
  color: ${({ theme }) => theme.colorTokens.content.link};
  cursor: pointer;
`;
