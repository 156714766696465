import React, { useEffect } from "react";
import type { TransitionState } from "@tvg/ts-types/Modal";
import tvgConf from "@tvg/conf";
import perf from "../../utils/performanceUtils";
import { Iframe } from "./styled-components";

interface Props {
  transitionState: TransitionState;
  path: string;
}

const AccountWalletWrapper = (props: Props) => {
  useEffect(() => {
    if (props.transitionState === "entered") {
      perf.measure("page_render", { page: "accountWallet" });
    }
  });

  const accountWalletUrl = tvgConf().buildUrl({
    app: "accountWallet",
    path: props.path,
    wrapper: true,
    callback: true
  });

  return (
    <Iframe
      src={accountWalletUrl}
      id="iframe"
      title="Account And Wallet"
      scrolling="yes"
      referrerPolicy="no-referrer"
    />
  );
};

export default AccountWalletWrapper;
