import React from "react";
import { Header } from "@tvg/design-system/web";
import { ColorTokens, useColorTokens } from "@tvg/design-system";
import Information from "../SvgImages/Information";
import { ErrorDescription, ErrorDescriptionEnum } from "../types";
import {
  DescriptionWrapper,
  DescriptionContent,
  DescriptionItem
} from "./styled-components";

export interface Props extends ErrorDescription {
  qaLabel?: string;
}

const ErrorPageDescription = ({
  qaLabel = "errorPage-description",
  type = ErrorDescriptionEnum.TEXT,
  title,
  messages
}: Props) => {
  const { content } = useColorTokens() as ColorTokens;

  return (
    <DescriptionWrapper data-qa-label={qaLabel}>
      {title && (
        <Header
          tag="h3"
          qaLabel={`${qaLabel}-title`}
          fontFamily="bold"
          color={content.onDark}
        >
          {title}
        </Header>
      )}
      {messages && (
        <DescriptionContent type={type}>
          {messages.map((message, index) => (
            <DescriptionItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              data-qa-label={`${qaLabel}-item-${index}`}
              isListItem={type === ErrorDescriptionEnum.STEPS}
            >
              {type === ErrorDescriptionEnum.TIPS && <Information />}
              {message}
            </DescriptionItem>
          ))}
        </DescriptionContent>
      )}
    </DescriptionWrapper>
  );
};

export default ErrorPageDescription;
