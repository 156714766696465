import { get, noop } from "lodash";
import { AxiosError } from "axios";
import decodeJWT from "jwt-decode";
import { getAuthTokenCookie } from "@tvg/sh-utils/sessionUtils";
import {
  GeoLocationResponseRejected,
  GeoLocationResponseSuccess
} from "@fdr/types/ts/GeoLocation";

import { decryptGeopacket, getLicense, renewLicense } from "./services";
import {
  GeoClientTokenStatus,
  GeolocationCallbackFailure,
  GeolocationCallbacks,
  GeoLocationConfig,
  GeolocationCustomErrorCodes,
  GeolocationCustomErrorMessages,
  GeolocationFailureResponse,
  GeolocationReason,
  GeolocationRelocateUserCallback,
  GeoPacket,
  GeoStorageKey
} from "./types/solus";
import {
  GeoClient,
  GeoClientErrorCodes,
  GeoClientErrorMessages,
  GeoClientEvent
} from "./types/gcHtml5";
import { getCacheGeoData, getGeoExpiryTime, setCacheGeoData } from "./utils";

// Shared singleton instance.
let geoClientInstance: GeoClient;

export const getGeoClientInstance = (): GeoClient | null => {
  if (
    !geoClientInstance &&
    (typeof window === "undefined" || typeof window.GcHtml5 === "undefined")
  ) {
    return null;
  }

  if (!geoClientInstance) {
    geoClientInstance = window.GcHtml5!.createClient(null, null);
  }

  return geoClientInstance;
};

export const setupGeoClientEventHandler = (
  geoClient: GeoClient,
  geoConfig: GeoLocationConfig,
  geoHandlers: GeolocationCallbacks,
  relocateUserCallback: GeolocationRelocateUserCallback = noop
) => {
  const { onFailed = noop } = geoHandlers;
  geoClientSuccessEventHandler(
    geoClient,
    geoConfig,
    geoHandlers,
    relocateUserCallback
  );
  geoClientErrorEventHandler(geoClient, onFailed, relocateUserCallback);
};

export const setupRelocateUser = (
  geolocation: GeoLocationResponseSuccess | GeoLocationResponseRejected,
  geolocationToken: string,
  geoHandlers: GeolocationCallbacks,
  relocateUserCallback: GeolocationRelocateUserCallback
) => {
  const geolocateIn = getGeoExpiryTime(get(geolocation, "geolocate_in"));
  if (geolocateIn) {
    const previousProcessId = Number(
      getCacheGeoData(GeoStorageKey.RELOCATE_ID)
    );
    clearTimeout(previousProcessId);
    const relocateUserProcessId = setTimeout(() => {
      relocateUserCallback(GeoClientTokenStatus.SUCCESSFUL_TOKEN);
      setGeopacket(
        geolocationToken,
        geolocation,
        geoHandlers,
        GeolocationReason.PERIODIC
      );
    }, geolocateIn);
    setCacheGeoData(
      GeoStorageKey.RELOCATE_ID,
      relocateUserProcessId.toString()
    );
  } else {
    console.warn(
      "[Solus]: Ignoring refresh flow because geolocate_in is missing"
    );
  }
};

export const geoClientSetGeoPacket = (
  geoConfig: GeoLocationConfig,
  geoHandlers: GeolocationCallbacks,
  packet: string,
  relocateUserCallback: GeolocationRelocateUserCallback
) => {
  const { onFailed = noop } = geoHandlers;
  const { product, state } = geoConfig;
  const geoPacket: GeoPacket = {
    geopacket: packet,
    state,
    product
  };
  const authToken = getAuthTokenCookie();

  if (!authToken) {
    console.error("[Solus]: Auth Token not found, can't decrypt geoPacket");
    return;
  }

  decryptGeopacket(authToken, geoPacket)
    .then((response) => {
      const geolocationToken: string = get(
        response,
        "data.geolocation_check[0].geolocation_token"
      );
      const geolocation:
        | GeoLocationResponseSuccess
        | GeoLocationResponseRejected = decodeJWT(geolocationToken);

      setGeopacket(geolocationToken, geolocation, geoHandlers);

      // Refresh
      setupRelocateUser(
        geolocation,
        geolocationToken,
        geoHandlers,
        relocateUserCallback
      );
    })
    .catch((responseError: AxiosError) => {
      const error =
        get(responseError, "response.data.error") ||
        get(responseError, "response.data.errors[0]");

      console.error("GeoComply Error: ", error);
      onFailed({
        code: GeolocationCustomErrorCodes.USER_UNAUTHORIZED,
        message: GeolocationCustomErrorMessages.USER_UNAUTHORIZED
      });
    });
};

export const geoClientSuccessEventHandler = (
  geoClient: GeoClient,
  geoConfig: GeoLocationConfig,
  geoHandlers: GeolocationCallbacks,
  relocateUserCallback: GeolocationRelocateUserCallback = noop
) => {
  geoClient.events.on(GeoClientEvent.SUCCESS_ENGINE, (packet: string) => {
    geoClientSetGeoPacket(geoConfig, geoHandlers, packet, relocateUserCallback);
  });
};

export const geoClientErrorEventHandler = (
  geoClient: GeoClient,
  callback: GeolocationCallbackFailure,
  relocateUserCallback: GeolocationRelocateUserCallback = noop
) => {
  geoClient.events.on(
    GeoClientEvent.FAILED_ALL,
    (errorCode: GeoClientErrorCodes, errorMessage: GeoClientErrorMessages) => {
      const failedCodes = [
        GeoClientEvent.FAILED_REVISE,
        GeoClientEvent.FAILED_CONFIG,
        GeoClientEvent.FAILED_ENGINE
      ];
      const renewTokenCodes = [
        GeoClientErrorCodes.CLNT_ERROR_LICENSE_EXPIRED,
        GeoClientErrorCodes.CLNT_ERROR_LICENSE_UNAUTHORIZED
      ];
      const error: GeolocationFailureResponse = {
        code: errorCode,
        message: errorMessage
      };

      if (
        !failedCodes.includes(geoClient.events.event) ||
        !renewTokenCodes.includes(errorCode)
      ) {
        callback(error);
      } else {
        relocateUserCallback(GeoClientTokenStatus.FAILED_TOKEN);
      }
    }
  );
};

export const requestGeopacket = () => {
  const geoClient = getGeoClientInstance();
  if (geoClient) {
    geoClient.request();
  }
};

export const getGeoClientLicense = async (
  config: GeoLocationConfig,
  previousLicense?: string
) => {
  const { state, product, licenseType } = config;
  const licenseResponse = previousLicense
    ? await renewLicense(previousLicense, state, product, licenseType)
    : await getLicense(state, product, licenseType);
  return get(licenseResponse, "data.geocomply_license[0].license");
};

export const injectLicenseInGeoClient = (
  license: string,
  hasValidGeoPacket: boolean
) => {
  const geoClient = getGeoClientInstance();
  if (geoClient && license) {
    setCacheGeoData(GeoStorageKey.LICENSE, license);
    geoClient.setLicense(license);
    if (!hasValidGeoPacket) geoClient.request();
  }
};

export const setGeopacket = (
  geolocationToken: string,
  geolocation: GeoLocationResponseSuccess | GeoLocationResponseRejected,
  geoHandlers: GeolocationCallbacks,
  reason: GeolocationReason = GeolocationReason.LOGIN
) => {
  const { onSuccess = noop, onReject = noop, onRefresh = noop } = geoHandlers;
  setCacheGeoData(GeoStorageKey.GEO_PACKET, geolocationToken);
  const errorMessage = get(geolocation, "error_message");
  const troubleshooters = get(geolocation, "troubleshooter", []);

  if (!errorMessage && !troubleshooters.length) {
    if (reason === GeolocationReason.PERIODIC) {
      onRefresh(geolocation, geolocationToken);
    } else {
      onSuccess(geolocation, geolocationToken);
    }
  } else {
    onReject(geolocation, geolocationToken);
  }
};
