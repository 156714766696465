export enum MapImageEnum {
  PIN = "pin",
  ERROR = "error"
}

export enum ErrorDescriptionEnum {
  TEXT = "text",
  TIPS = "tips",
  STEPS = "steps"
}

export interface ErrorDescription {
  type?: ErrorDescriptionEnum;
  title?: string;
  messages?: string[];
}

export interface ErrorMessage {
  title?: string;
  message: string;
}
