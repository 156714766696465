import mediator from "@tvg/mediator";
import { getSportsbookRegionCookie } from "@tvg/sh-utils/sessionUtils";

export const handle2FAStateMismatch = (
  region: string,
  twoFactorAuthStates: Array<string> = []
) =>
  getSportsbookRegionCookie()?.toUpperCase() !== region?.toUpperCase() &&
  twoFactorAuthStates?.includes(region?.toUpperCase()) &&
  mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });
