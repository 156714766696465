import type { EventHandler, SyntheticEvent } from "react";

export enum GeoComplyEvents {
  FAILURE = "GEOCOMPLY_FAILURE",
  VERIFIED = "GEOCOMPLY_VERIFIED",
  RETRY = "GEOCOMPLY_RETRY",
  REJECT = "GEOCOMPLY_REJECT",
  REFRESH = "GEOCOMPLY_REFRESH",
  REQUEST = "GEOCOMPLY_REQUEST"
}

export interface GeoComplyDynamicValues {
  twoFactorAuthStates: Array<string>;
  wageringStates: Array<string>;
}

export interface GeoComplyGPSInvalidStateMessage {
  title: string;
  text: string;
}

export interface LinkAction {
  text: string;
  url: string;
  onClick?: EventHandler<SyntheticEvent>;
}

export interface FixedBannerProps {
  qaLabel?: string;
  title?: string;
  text?: string;
  link?: LinkAction;
  onClose?: EventHandler<SyntheticEvent>;
}

export interface GeolocationErrorMessage {
  type: string;
  messages: LinkAction;
  title?: string;
}

export interface GeoLocationErrorModalTips {
  LOCATION_FAILURE: string;
}
