import React, { Fragment, useEffect, useState } from "react";
import { noop } from "lodash";
import formatCurrency from "@tvg/formatter/currency";
import cusService from "@tvg/api/cus";
import statesMock from "@tvg/api/cus/__mocks__/cus.mock";
import { FloatingBanner } from "../MessageV2";
import BoxFactory from "./boxFactory";
import {
  BalanceContainer,
  BalanceDescription,
  BalanceValue,
  Container
} from "./styled-components";

const getUserBalance = (userBalance, lowBalanceMessage) => {
  const lowBalance = userBalance < 10;
  return (
    <Fragment>
      {lowBalance && <FloatingBanner type="warning" text={lowBalanceMessage} />}
      <BalanceContainer isLowBalance={lowBalance}>
        <BalanceDescription>Racing Balance</BalanceDescription>
        <BalanceValue>{formatCurrency(userBalance)}</BalanceValue>
      </BalanceContainer>
    </Fragment>
  );
};

const LoginBox = (props) => {
  const {
    openSelectStateGtm,
    selectStateGtm,
    boxType,
    stateError,
    stateAbbr,
    hideSecondaryButton,
    handleButton,
    handleSecondaryButton,
    handleSubmit,
    qaLabel,
    boxLabels,
    brand,
    showUserBalance,
    userBalance,
    lowBalanceMessage,
    isAndroidBlocked
  } = props;
  const [state, setState] = useState("");
  const [statesList, setStatesList] = useState(statesMock);

  useEffect(() => {
    if (boxType === "selectStateManually") {
      cusService.getStates("FDR").then(({ data: { states } }) => {
        setStatesList(states);
      });
    }
  }, [boxType]);

  const boxFactoryProps = {
    openSelectStateGtm,
    selectStateGtm,
    boxType,
    states: statesList,
    state,
    setState,
    stateError,
    stateAbbr,
    hideSecondaryButton,
    handleButton,
    handleSecondaryButton,
    handleSubmit,
    qaLabel,
    boxLabels,
    brand,
    isAndroidBlocked
  };

  return boxType !== "simpleSelection" &&
    boxType !== "blockedCountryAndroid" ? (
    <Container data-qa-label="login-message-footer">
      {showUserBalance && getUserBalance(userBalance, lowBalanceMessage)}
      <BoxFactory {...boxFactoryProps} />
    </Container>
  ) : (
    <BoxFactory {...boxFactoryProps} />
  );
};

LoginBox.defaultProps = {
  openSelectStateGtm: noop,
  selectStateGtm: noop,
  boxType: "blockedCountry",
  boxLabels: {
    primaryButton: ""
  },
  stateError: false,
  stateAbbr: "",
  hideSecondaryButton: false,
  handleButton: noop,
  handleSecondaryButton: noop,
  handleSubmit: noop,
  qaLabel: "",
  brand: "",
  userBalance: 0,
  showUserBalance: false,
  lowBalanceMessage: "",
  isAndroidBlocked: false
};

export default LoginBox;
