import {
  GeolocationRejectedErrorMessage,
  GeolocationRejectedErrorModalTitle
} from "../types";

export const getRejectedErrorTitle = (
  geoLocationRejectedErrorModalTitle: GeolocationRejectedErrorModalTitle,
  geolocationError: GeolocationRejectedErrorMessage[]
) =>
  geolocationError?.map((error) => {
    if (!error?.title && error.rule in geoLocationRejectedErrorModalTitle) {
      return {
        ...error,
        title: geoLocationRejectedErrorModalTitle[error.rule]
      };
    }
    return error;
  });
