/* eslint-disable */
import { Dispatch } from "redux";
import { GeolocationRejectedErrorMessage } from "../types";
import {
  GeoComplyDynamicValues,
  GeoComplyGPSInvalidStateMessage
} from "./types";

const getCookie = (name: string): string | null =>
  document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((cookie) => cookie.substring(0, name.length + 1) === `${name}=`)
    .map((cookie) =>
      decodeURIComponent(cookie.substring(name.length + 1))
    )[0] || null;

export const startMockTool = (
  dispatch: Dispatch,
  dynamicValues: Partial<GeoComplyDynamicValues>,
  geolocationGPSInvalidMessage: GeoComplyGPSInvalidStateMessage,
  verifiedCallback: Function,
  rejectedCallback: Function,
  failureCallback: Function
) => {
  if (typeof window !== undefined) {
    // @ts-ignore
    window.forceGeocomply = () => {
      const getValue = (text: string, canEmpty = false) => {
        while (true) {
          const value = prompt(text);
          if (value === null && !canEmpty) return "-1";
          if (value || canEmpty) return value;
          alert("Please enter with some value");
        }
      };

      const cleanCookie = () => {
        document.cookie = `force-geolocation.token=; Max-Age=-99999999; path=/`;
        document.cookie = `force-geolocation.region=; Max-Age=-99999999; path=/`;
        document.cookie = `force-geolocation.troubleshooters=; Max-Age=-99999999; path=/`;
        document.cookie = `force-geolocation.error=; Max-Age=-99999999; path=/`;
      };

      while (true) {
        const choose = prompt(
          "Please write 1,2,3 or 4 option. \n 1- Success Geolocation \n 2- Rejection Geolocation \n 3- Error Geolocation \n 4- Clean Mocks \n 0- Quit"
        );
        if (choose && ["1", "2", "3", "4", "0"].includes(choose)) {
          if (choose == "1") {
            const geoToken = getValue("Please enter with geo token: ");
            if (geoToken == "-1") return;

            const geoRegion = getValue("Please enter with region: ");
            if (geoRegion == "-1") return;

            cleanCookie();
            document.cookie = `force-geolocation.token=${geoToken}; path=/`;
            document.cookie = `force-geolocation.region=${geoRegion}; path=/`;
            return;
          }
          if (choose == "2") {
            const geoTroubleshooters = [];
            while (confirm("Do you want add new troubleshooter?")) {
              const message = getValue("Please enter troubleshooter message");
              if (message == "-1") continue;

              const rule = getValue("Please enter troubleshooter rule");
              if (rule == "-1") continue;

              const retry = confirm("This troubleshooter is retryable?");

              const title = getValue(
                "Please enter troubleshooter title (Optional)",
                true
              );
              const helpLink = getValue(
                "Please enter with troubleshooter helLink (Optional)",
                true
              );
              const optInLink = getValue(
                "Please enter with troubleshooter optIn link (Optional)",
                true
              );

              const troubleshooter = {
                message,
                retry,
                title,
                helpLink,
                optInLink
              };

              geoTroubleshooters.push(troubleshooter);
            }

            const geoRegion = getValue("Please enter with region: ");
            if (geoRegion == "-1") return;
            cleanCookie();
            document.cookie = `force-geolocation.troubleshooters=${JSON.stringify(
              geoTroubleshooters
            )}; path=/`;
            document.cookie = `force-geolocation.region=${geoRegion}; path=/`;
            return;
          }
          if (choose == "3") {
            const geoError = getValue("Please enter with the error code: ");
            cleanCookie();
            document.cookie = `force-geolocation.error=${geoError}; path=/`;
            return;
          }
          if (choose == "4") {
            cleanCookie();
            return;
          }
          if (choose == "0") {
            return;
          }
        } else if (choose === null) {
          return;
        } else {
          alert("Please choose 1,2,3,4 or 0");
        }
      }
    };

    // @ts-ignore
    window.copyGeocomply = () => {
      const token = getCookie("force-geolocation.token");
      const region = getCookie("force-geolocation.region");
      const troubleshooters = getCookie("force-geolocation.troubleshooters");
      const error = getCookie("force-geolocation.error");

      const cookies = {
        "force-geolocation.token": token,
        "force-geolocation.region": region,
        "force-geolocation.troubleshooters": troubleshooters,
        "force-geolocation.error": error
      };

      const cookiesString = Object.entries(cookies).reduce(
        (acc, [key, value]) => {
          if (value) {
            return `${acc}${key}=${value}; path=/|`;
          }

          return acc;
        },
        ""
      );

      // @ts-ignore
      window.copy(cookiesString);
    };

    // @ts-ignore
    window.setGeocomply = (cookieStr: string) => {
      cookieStr.split("|").forEach((cookieValue) => {
        document.cookie = cookieValue;
      });
    };
  }

  const forceGeolocation = () => {
    try {
      const token = getCookie("force-geolocation.token");
      const region = getCookie("force-geolocation.region");
      const troubleshooters = getCookie("force-geolocation.troubleshooters");
      const error = getCookie("force-geolocation.error");

      if (token) {
        verifiedCallback(
          dispatch,
          dynamicValues,
          geolocationGPSInvalidMessage,
          forceGeolocation
        )(token, region);
      } else if (troubleshooters) {
        const parsedTroubleshooters: Array<GeolocationRejectedErrorMessage> =
          JSON.parse(troubleshooters);
        rejectedCallback(dispatch, dynamicValues)(
          parsedTroubleshooters,
          region
        );
      } else if (error) {
        failureCallback(dispatch, forceGeolocation)(error);
      }

      return token || region || troubleshooters || error;
    } catch (err) {
      console.error(err);
    }

    return null;
  };

  return forceGeolocation;
};
