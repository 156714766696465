import styled from "styled-components";

export const ErrorListWrapper = styled.div``;

export const MessageBlock = styled.div`
  display: flex;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.notification.info.backgroundSubtle};
  border-radius: 4px;
  padding: 12px 16px;
  margin-top: 16px;
`;

export const TitleMessage = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  & > svg {
    margin-right: 8px;
  }
`;

export const ContentMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContent = styled.div`
  margin-right: 10px;
`;
