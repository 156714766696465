import React, { Fragment } from "react";
import { get, map } from "lodash";

import { arrowDown } from "@fdr/static-ui/Icons/icons";
import buildColor from "@fdr/static-ui/ColorPalette";
import Bubble1 from "@fdr/static-ui/SvgImages/Bubble1";
import Bubble2 from "@fdr/static-ui/SvgImages/Bubble2";
import Button from "../../_atoms/Button";
import { FloatingBanner } from "../MessageV2";
import {
  InfoMessageWrapper,
  BoxSubContainer,
  BoxSubWrapper,
  BoxSubTitle,
  BoxText,
  SelectContainer,
  Select,
  ArrowDown,
  StateError
} from "./styled-components";

const BoxFactory = ({
  openSelectStateGtm,
  selectStateGtm,
  boxType,
  states,
  state,
  setState,
  stateError,
  stateAbbr,
  hideSecondaryButton,
  handleButton,
  handleSecondaryButton,
  handleSubmit,
  qaLabel,
  boxLabels,
  brand
}) => {
  const icons = [Bubble1, Bubble2];
  switch (boxType) {
    case "simpleSelection":
      return (
        <Button
          isExternalLink
          onClick={(e) => {
            e.preventDefault();
            handleButton();
          }}
          type="betting"
          qaLabel={`${qaLabel}-${brand}Account-footer`}
        >
          {get(boxLabels, "primaryButton", "")}
        </Button>
      );
    case "selectStateManually":
      return (
        <Fragment>
          <SelectContainer stateError={stateError}>
            <Select
              value={state || stateAbbr}
              onClick={() => openSelectStateGtm()}
              onChange={(e) => {
                const { value } = e.target;
                selectStateGtm(value);
                setState(value);
              }}
              state={state}
              data-qa-label={`${qaLabel}StateSelect`}
            >
              <option value="" disabled hidden>
                {get(boxLabels, "primaryButton", "")}
              </option>
              {states.map(({ name, abbreviation }) => (
                <option value={abbreviation} key={abbreviation}>
                  {name}
                </option>
              ))}
            </Select>
            <ArrowDown icon={arrowDown} color={buildColor("blue", "500")} />
          </SelectContainer>
          {stateError && (
            <StateError data-qa-label={`${qaLabel}StateSelectErrorMessage`}>
              {get(boxLabels, "error", "")}
            </StateError>
          )}
          <Button
            type="primary"
            onClick={() => handleSubmit(state)}
            qaLabel={`${qaLabel}-selectState-footer`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "accountDetected":
      return (
        <Fragment>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}-${brand}Account-footer`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            isInternalLink
            type="simpleLink"
            onClick={(e) => {
              e.preventDefault();
              handleSecondaryButton();
            }}
            qaLabel={`${qaLabel}SecondaryButton`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "accountMigrated":
      return (
        <Fragment>
          <Button
            isInternalLink
            type="betting"
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}-${brand}Account-footer`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            isInternalLink
            type="simpleLink"
            onClick={(e) => {
              e.preventDefault();
              handleSecondaryButton();
            }}
            qaLabel={`${qaLabel}SecondaryButton`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "allowLocation":
      return (
        <Fragment>
          <Button
            onClick={() => handleButton()}
            qaLabel={`${qaLabel}-allowLocation-footer`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            type="simpleLink"
            onClick={() => handleSecondaryButton()}
            qaLabel={`${qaLabel}-allowState-footer`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "allowLocationSplash":
      return (
        <Fragment>
          <Button
            onClick={() => handleButton()}
            qaLabel={`${qaLabel}-allowLocation-footer`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            type="simpleLink"
            onClick={() => handleSecondaryButton()}
            qaLabel={`${qaLabel}SecondaryButton`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "allowLocationIOS":
      return (
        <Fragment>
          <InfoMessageWrapper>
            <FloatingBanner
              type="info"
              text="If prompted to share your current location press “ok” or “allow”"
            />
          </InfoMessageWrapper>
          <Button
            onClick={() => handleButton()}
            qaLabel={`${qaLabel}-allowLocationIOS-footer`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
        </Fragment>
      );
    case "locationErrors":
      return (
        <Fragment>
          {map(get(boxLabels, "topics", []), (message, index) => {
            const { title, text } = message;
            const Bubble = get(icons, index);
            return (
              <BoxSubContainer>
                {Bubble && <Bubble />}
                <BoxSubWrapper>
                  <BoxSubTitle
                    data-qa-label={`${qaLabel}BoxSubTitle${index + 1}`}
                  >
                    {title}
                  </BoxSubTitle>
                  <BoxText data-qa-label={`${qaLabel}BoxText${index + 1}`}>
                    {text}
                  </BoxText>
                </BoxSubWrapper>
              </BoxSubContainer>
            );
          })}
          {!hideSecondaryButton ? (
            <Fragment>
              <Button
                onClick={() => handleButton()}
                qaLabel={`${qaLabel}-selectState-footer`}
              >
                {get(boxLabels, "primaryButton", "")}
              </Button>
              <Button
                type="simpleLink"
                onClick={handleSecondaryButton}
                qaLabel={`${qaLabel}-confirmLocation-footer`}
              >
                {get(boxLabels, "secondaryButton", "")}
              </Button>
            </Fragment>
          ) : (
            <Button onClick={handleButton} qaLabel={`${qaLabel}PrimaryButton`}>
              {get(boxLabels, "extraButton", "")}
            </Button>
          )}
        </Fragment>
      );
    case "moreInfo":
      return (
        <Fragment>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}PrimaryButton`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleSecondaryButton();
            }}
            type="simpleLink"
            qaLabel={`${qaLabel}-somethingWentWrong`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "invalidWageringState":
      return (
        <Fragment>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}PrimaryButton`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            isInternalLink
            type="simpleLink"
            onClick={(e) => {
              e.preventDefault();
              handleSecondaryButton();
            }}
            qaLabel={`${qaLabel}-invalidWageringState-footer`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "blockedCountry":
      return (
        <Fragment>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}-blockedCountry-footer`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            isInternalLink
            type="simpleLink"
            onClick={(e) => {
              e.preventDefault();
              handleSecondaryButton();
            }}
            qaLabel={`${qaLabel}SecondaryButton`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "needMoreInformation":
      return (
        <Fragment>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}PrimaryButton`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
          <Button
            isInternalLink
            type="simpleLink"
            onClick={(e) => {
              e.preventDefault();
              handleSecondaryButton();
            }}
            qaLabel={`${qaLabel}-needMoreInformation-footer`}
          >
            {get(boxLabels, "secondaryButton", "")}
          </Button>
        </Fragment>
      );
    case "noInternetConnection":
      return (
        <Fragment>
          <Button
            isInternalLink
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}PrimaryButton`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
        </Fragment>
      );
    case "blockedCountryAndroid":
      return (
        <Fragment>
          <Button
            isStretched
            type="secondary"
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            qaLabel={`${qaLabel}PrimaryButton`}
          >
            {get(boxLabels, "primaryButton", "")}
          </Button>
        </Fragment>
      );
    /* istanbul ignore next line */
    default:
      return null;
  }
};

export default React.memo(BoxFactory);
