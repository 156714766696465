import styled, { createGlobalStyle } from "styled-components";

// We need to override the z-index of SDK login/signup modal
// since it is lower than the application modal, which we can't change
export const AWGlobalStyle = createGlobalStyle`
  body > div#modal {
    z-index: 9999;
  }
`;

export const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;
