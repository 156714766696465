import React from "react";

const AndroidBlockedSVG = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="email">
      <path
        id="Path"
        d="M99.6025 199.292C154.611 199.292 199.205 154.679 199.205 99.6462C199.205 44.6131 154.611 0 99.6025 0C44.5935 0 0 44.6131 0 99.6462C0 154.679 44.5935 199.292 99.6025 199.292Z"
        fill="#CEDAE7"
      />
      <g id="Clipped">
        <mask
          id="mask0"
          maskType="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="200"
          height="200"
        >
          <path
            id="Path_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.6025 199.292C154.611 199.292 199.205 154.679 199.205 99.6462C199.205 44.6131 154.611 0 99.6025 0C44.5935 0 0 44.6131 0 99.6462C0 154.679 44.5935 199.292 99.6025 199.292Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            id="Path_3"
            d="M99.6025 199.292C154.611 199.292 199.205 154.679 199.205 99.6462C199.205 44.6131 154.611 0 99.6025 0C44.5935 0 0 44.6131 0 99.6462C0 154.679 44.5935 199.292 99.6025 199.292Z"
            fill="#CEDAE7"
          />
        </g>
      </g>
      <g id="Clipped_2">
        <mask
          id="mask1"
          maskType="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="200"
          height="200"
        >
          <path
            id="Path_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.6025 199.292C154.611 199.292 199.205 154.679 199.205 99.6462C199.205 44.6131 154.611 0 99.6025 0C44.5935 0 0 44.6131 0 99.6462C0 154.679 44.5935 199.292 99.6025 199.292Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1)">
          <g id="Group 906">
            <g id="Rectangle 3">
              <path
                d="M152.328 29.8155L-42.5889 68.1211L-16.5411 200.665L178.376 162.359L152.328 29.8155Z"
                fill="#C3D2E1"
              />
              <path
                d="M-41.6562 68.7474L70.9579 130.833L151.702 30.7482M-42.5889 68.1211L152.328 29.8155L178.376 162.359L-16.5411 200.665L-42.5889 68.1211Z"
                stroke="#C3D2E1"
                strokeWidth="3.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Rectangle 2">
              <path
                d="M133.833 19.7471L-61.0835 58.0527L-35.0357 190.596L159.881 152.291L133.833 19.7471Z"
                fill="#F4F8FB"
              />
              <path
                d="M-60.1508 58.679L52.4633 120.765L133.207 20.6798M-61.0835 58.0527L133.833 19.7471L159.881 152.291L-35.0357 190.596L-61.0835 58.0527Z"
                stroke="#DBE4ED"
                strokeWidth="7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <path
              id="Path_5"
              d="M37.0397 63.9012C37.3064 62.4647 37.6891 61.0764 38.1904 59.7569C38.3919 59.2256 38.612 58.7138 38.8471 58.2178C38.8924 58.1204 38.8059 58.016 38.7003 58.0409C38.6107 58.063 38.521 58.0853 38.4314 58.1086C34.8701 59.0381 31.6027 60.5218 28.6965 62.4395C27.1899 63.4331 25.7829 64.5431 24.4818 65.7544C24.1237 66.0877 23.983 66.588 24.1163 67.053C24.6152 68.7835 25.2494 70.4404 26.006 72.0155C26.7175 73.4968 27.5376 74.9048 28.4518 76.2325C29.5526 77.8293 30.7921 79.3109 32.1502 80.6637C34.654 83.1594 37.5575 85.2206 40.7291 86.7683C41.2729 87.0335 41.823 87.2832 42.3814 87.518C42.5029 87.5693 42.6119 87.4276 42.5343 87.3233C42.2197 86.8995 41.9153 86.4638 41.621 86.0167C40.2142 83.8715 39.0622 81.4664 38.2099 78.8899C38.1745 78.7775 38.106 78.6785 38.0136 78.6034C37.1364 77.8889 36.3028 77.1189 35.5219 76.2941C35.9417 75.9118 36.3821 75.5505 36.8449 75.2127C37.0479 75.0643 37.1469 74.8196 37.1038 74.5762C36.773 72.7882 36.5952 71.0029 36.5789 69.2497C36.5781 69.1546 36.4775 69.0942 36.3906 69.1382C34.871 69.9243 33.471 70.8785 32.2125 71.971C31.4476 70.7209 30.7807 69.3963 30.2261 68.0021C32.1519 66.5334 34.3021 65.3079 36.6404 64.3837C36.8482 64.3004 36.9981 64.1192 37.0397 63.9012Z"
              fill="#CEDAE7"
            />
            <path
              id="Path_6"
              d="M54.3382 57.9636C52.6121 57.5425 50.8409 57.2622 49.0428 57.1311C46.6417 56.9561 44.1941 57.0474 41.733 57.427C41.5389 57.4576 41.3681 57.5728 41.2692 57.7415C40.3153 59.39 39.5912 61.2812 39.1254 63.3384C39.1048 63.4333 39.1939 63.5154 39.2869 63.4886C39.4554 63.4407 39.6275 63.3935 39.7997 63.3486C43.3802 62.4137 46.9798 62.2419 50.4211 62.7306C50.619 64.218 50.6852 65.7005 50.626 67.1641C50.5553 68.998 50.2931 70.8038 49.8506 72.5548C48.9187 76.2374 47.1989 79.6786 44.8296 82.6434C43.6121 82.1474 42.4333 81.5657 41.3041 80.8992C41.1963 80.8356 41.068 80.947 41.1194 81.0604C41.6899 82.3361 42.3371 83.5391 43.0498 84.6596C44.0423 86.223 45.1661 87.622 46.3771 88.8309C46.4274 88.8818 46.511 88.8815 46.562 88.8302C47.9943 87.4176 49.2875 85.8792 50.4248 84.2382C52.4362 81.3379 53.9636 78.1205 54.9297 74.7191C55.4533 72.8752 55.8108 70.9767 55.9926 69.0451C56.1413 67.4394 56.1698 65.8106 56.0662 64.1704C55.9561 62.4264 55.7032 60.671 55.2922 58.9169C55.18 58.4476 54.8129 58.0795 54.3382 57.9636Z"
              fill="#CEDAE7"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AndroidBlockedSVG;
