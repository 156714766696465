import React from "react";
import { noop, get } from "lodash";

import { cross, fanduel } from "@fdr/static-ui/Icons/icons";
import Icon from "@fdr/static-ui/Icons";
import { LogoFDR } from "@fdr/static-ui/Logos";
import buildColor from "@fdr/static-ui/ColorPalette";
import LoginMessage from "../../_molecules/LoginMessage";
import LoginBox from "../../_molecules/LoginBox";
import AccountMigrationSelections from "../../_molecules/AccountMigrationSelections";
import {
  Container,
  HeaderWrapper,
  LogoWrapper,
  Close,
  FooterWrapper,
  FanduelIcon
} from "./styled-components";

export const getFanduelIcon = (isFanduel) =>
  isFanduel ? (
    <FanduelIcon size={60} icon={fanduel} color={buildColor("blue", "500")} />
  ) : null;

const ErrorPage = ({
  openSelectStateGtm,
  selectStateGtm,
  showClose,
  handleClose,
  iconType,
  boxType,
  boxLabels,
  stateError,
  stateAbbr,
  hideSecondaryButton,
  handleButton,
  handleSecondaryButton,
  handleSubmit,
  message,
  brand,
  showLoginBox,
  textType,
  isMigration,
  showHeader,
  isAndroidBlocked,
  androidBlockType
}) => (
  <Container isAndroidBlocked={isAndroidBlocked} data-qa-label="login-section">
    {showHeader && (
      <HeaderWrapper data-qa-label="login-message-header">
        <LogoWrapper data-qa-label="loginLogo">
          {LogoFDR({
            height: 30,
            fillColor: isAndroidBlocked ? "#1E375B" : buildColor("white", "100")
          })}
        </LogoWrapper>
        {showClose && (
          <Close onClick={() => handleClose()} data-qa-label="loginCloseButton">
            <Icon
              data-qa-label="login-icon-close"
              icon={cross}
              color={buildColor("blue", "400")}
              size={24}
            />
          </Close>
        )}
      </HeaderWrapper>
    )}
    <LoginMessage
      iconComponent={getFanduelIcon(iconType === "fdr")}
      iconType={iconType}
      title={get(message, "title", "")}
      Text={get(message, "text", "")}
      qaLabel="login"
      textType={textType}
      isMigration={isMigration}
      isInjection
      isAndroidBlocked={isAndroidBlocked}
      androidBlockType={androidBlockType}
    />
    {showLoginBox && (
      <FooterWrapper>
        {!isMigration ? (
          <LoginBox
            openSelectStateGtm={openSelectStateGtm}
            selectStateGtm={selectStateGtm}
            boxType={boxType}
            boxLabels={boxLabels}
            stateError={stateError}
            stateAbbr={stateAbbr}
            hideSecondaryButton={hideSecondaryButton}
            handleButton={handleButton}
            handleSecondaryButton={handleSecondaryButton}
            handleSubmit={handleSubmit}
            qaLabel="login"
            brand={brand}
            isAndroidBlocked={isAndroidBlocked}
          />
        ) : (
          <AccountMigrationSelections
            qaLabel="login"
            brand={brand}
            chooseMessage={get(message, "chooseMessage", "")}
            handleButton={handleButton}
            handleSecondaryButton={handleSecondaryButton}
          />
        )}
      </FooterWrapper>
    )}
  </Container>
);

ErrorPage.defaultProps = {
  openSelectStateGtm: noop,
  selectStateGtm: noop,
  showClose: true,
  handleClose: noop,
  iconType: "error",
  boxType: "blockedCountry",
  boxLabels: {},
  stateError: false,
  stateAbbr: "",
  hideSecondaryButton: false,
  handleButton: noop,
  handleSecondaryButton: noop,
  handleSubmit: noop,
  brand: "",
  showLoginBox: true,
  textType: "text",
  isMigration: false,
  showHeader: true,
  isAndroidBlocked: false,
  androidBlockType: "country"
};

export default React.memo(ErrorPage);
